import { createModel } from '@rematch/core';
import _debounce from 'lodash.debounce';
import {
  fetchBISConfigAPI,
  fetchBISReportAPI,
  fetchBISWidgetAPI,
  setBISConfigAPI,
  setBISWidgetAPI,
} from 'src/lib/api/automation/back-in-stock';
import store from 'src/store';
import { RootModel } from 'src/store/models';
import { AutomationConfig, PD_BIS_Report, Widget } from './types';

// debounced api call
const saveWidget = _debounce(
  (widget: any, successCallback: () => void, errorCallback: () => void) => {
    setBISWidgetAPI(widget).then(res => {
      if (!res.error) {
        successCallback();
      } else {
        errorCallback();
      }
    });
  },
  500,
);

interface BackInStockState {
  isFetching: boolean;
  isChanged: boolean;
  config: {
    enabled: boolean;
    metadata: AutomationConfig['metadata'];
  };
  report: PD_BIS_Report;
  widget: Widget;
}

const initialState = (): BackInStockState => ({
  isFetching: true,
  isChanged: false,
  config: {
    enabled: false,
    metadata: {
      title: '',
      description: '',
      redirect_url: '',
      actions: [],
    },
  },
  report: {
    pending: [],
    sent: [],
    summary: {
      attributed_revenue: 0,
      clicked: 0,
      delivered: 0,
      sent: 0,
      unique_variants: 0,
      in_queue: 0,
    },
  },
  widget: {
    enabled: false,
    is_viewed: false,
    metadata: {
      title: '',
      yes_button: {
        text: '',
      },
      post_subscription: {
        post_subscription_widget: {
          title: '',
        },
      },
    },
  },
});

const backInStockAutomation = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchConfig() {
      const { data, error } = await fetchBISConfigAPI();

      if (!error) {
        this.storeState({ config: data });
      }
    },

    async fetchReportAndWidget() {
      this.setFetching(true);

      const [
        { data: report, error: reportError },
        { data: widget, error: widgetError },
      ] = await Promise.all([fetchBISReportAPI(), fetchBISWidgetAPI()]);

      if (reportError || widgetError) {
        dispatch.saveToast.showError('Error fetching settings');
        return;
      }

      this.storeState({ report, widget });
    },

    async toggle(payload: boolean, rootState) {
      const state = rootState.backInStockAutomation;
      const newState = {
        ...state,
        widget: {
          ...state.widget,
          enabled: payload,
        },
      };

      this.storeState(newState);

      // make network request
      await setBISWidgetAPI(newState.widget).then(res => {
        if (!res.error) {
          store.dispatch.saveToast.showDone('Changes saved');
        } else {
          store.dispatch.saveToast.showError('Error saving changes');
          this.storeState(state);
        }
      });
    },

    async saveConfigMetadata(
      payload: AnyObject,
      rootState,
    ): Promise<{ data: any; error: any }> {
      const state = rootState.backInStockAutomation;
      const newState = {
        ...state,
        config: {
          ...state.config,
          metadata: payload,
        },
      };

      // make network request
      return setBISConfigAPI(newState.config).then(res => {
        if (!res.error) {
          this.storeState(newState);
          store.dispatch.saveToast.showDone('Changes saved');
        } else {
          this.storeState(state);
          store.dispatch.saveToast.showError('Error saving changes');
        }

        return res;
      });
    },

    async handleWidgetMetadataChange(payload: AnyObject, rootState) {
      const state = rootState.backInStockAutomation;
      const newState = {
        ...state,
        isChanged: true,
        widget: {
          ...state.widget,
          metadata: payload,
        },
      };

      this.storeState(newState);
    },

    async saveWidgetMetadata(payload: AnyObject, rootState) {
      const state = rootState.backInStockAutomation;
      const newState = {
        ...state,
        isChanged: true,
        widget: {
          ...state.widget,
          metadata: payload,
        },
      };

      this.storeState(newState);
      // make network request with debounced function
      saveWidget(
        newState.widget,
        () => {
          store.dispatch.saveToast.showDone('Changes saved');
        },
        () => {
          this.storeState(state);
        },
      );
    },
  }),

  reducers: {
    setFetching(state, isFetching) {
      return {
        ...state,
        isFetching,
      };
    },
    storeState(state, payload) {
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    },
  },
});

export default backInStockAutomation;
