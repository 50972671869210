export const CALENDAR_CELL_CLASS_NAMES = {
  DAY_PAST: 'fc-day-past',
  DAY_TODAY: 'fc-day-today',
  DAY_FUTURE: 'fc-day-future',
  DAY_HAS_EVENTS: 'fc-day-has-events',
  DAY_EMPTY: 'fc-day-empty',
  PLUS_ICON: 'plus-icon',
  DAYGRID_DAY_FRAME: 'fc-daygrid-day-frame',
};

export enum CHECKLIST_TASK_STATUS {
  TODO = 'todo',
  DONE = 'done',
}


export enum PopoverType {
  scheduledCampaignPopover = 'SCHEDULED_CAMPAIGN_POPOVER',
  tasklistPopover = 'TASKLIST_POPOVER',
}

export enum EVENT_TYPE {
  SENT_CAMPAIGN = 'CAMPAIGN',
  NOT_SENT_CAMPAIGN = 'NOT_SENT_CAMPAIGN',
  NOT_SENT_CAMPAIGN_IN_THE_PAST = 'NOT_SENT_CAMPAIGN_IN_THE_PAST',
  TASK = 'TASK',
  PAST_FESTIVAL = 'FESTIVAL',
  PRESENT_AND_FUTURE_FESTIVAL = 'PRESENT_AND_FUTURE_FESTIVAL',
}

export enum OnboardingStep {
  WELCOME = 0,
  LEGENDS = 1,
  TODAY_CELL = 2,
  DRAWER = 3,
}
