import { FullStory } from '@fullstory/browser';
import { createModel } from '@rematch/core';
import Router from 'next/router';
import posthog from 'posthog-js';
import {
  connectBrevoAPI,
  createDomainAPI,
  createNewAttributeAPI,
  createNewListAPI,
  fetchDataSyncStatusAPI,
  fetchDomainAPI,
  fetchRedirectURLAPI,
  fetchSubAccountAPI,
  getAccountInfoAPI,
  getContactAttributesAPI,
  getContactMappingAPI,
  getDoubleTemplatesAPI,
  getFinalTemplatesAPI,
  getfoldersAPI,
  getFraudDetectionAPI,
  getListMappingAPI,
  getListSelectionAPI,
  getOptInsAPI,
  getSenderAPI,
  resyncAPI,
  saveContactMappingAPI,
  saveListAPI,
  saveOptInsAPI,
  startDataSyncAPI,
  submitFraudDetectionAPI,
  verifyOTP,
  verifyPhoneNumber,
} from 'src/lib/api/brevo';
import { createMerchantTaskAPI } from 'src/lib/api/misc';
import { MerchantTask } from 'src/lib/constants';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { RootModel } from 'src/store/models';
import {
  EmailOnboardingStep,
  OnboardingSource,
  OnboardingStatus,
} from './constants';
import { SubscriptionConfirmation } from './types';

export const DO_NOT_IMPORT = 'do_not_import';

export type EmailOnboardingStatus = {
  dataSyncStatus: OnboardingStatus;
  domainVerificationStatus: OnboardingStatus;
  senderVerificationStatus: OnboardingStatus;
};

export const validBrevoRedirectURLs = [
  'marketing-campaign/list',
  'marketing-campaign/campaign-setup',
  'automation/automations',
  'contact/segment',
  'advanced/config',
  'templates/listing',
  'camp/message/stats/sms',
  'camp/lists/sms',
] as const;

export type ValidBrevoRedirectURLs = (typeof validBrevoRedirectURLs)[number];

const totalOnboardingSteps = Object.values(EmailOnboardingStep).filter(
  // because ts enum object.values() also has the keys for some reason
  Number.isFinite,
).length;
type DataSyncStatus = 'not_started' | 'failed' | 'completed' | 'in_progress';

type EmailModelState = {
  onboarding: {
    currentStep: number;
    senderEmailVerificationSent: boolean;
    isDomainSuccessfullyAdded: boolean;
  };
  subAccountId: number;
  onboardingStatus: EmailOnboardingStatus | null;
  createdAt: string;
  isLoading: boolean;
  permissionURL: string;
  fraudDetection: {
    status: 'pass' | 'block' | null;
    email: string | null;
    phone: string | null;
    failed_checks: string[] | null;
  };
  companyName?: string | null;
  connectionErrors: Array<string>;
  connectingBrevoAccount: boolean;
  fetchingAccountInfo: boolean;
  dataSyncStatus: {
    fetching: boolean;
    customer: DataSyncStatus | null;
    order: DataSyncStatus | null;
    product_variant: DataSyncStatus | null;
    collection: DataSyncStatus | null;
    product: DataSyncStatus | null;
  };
  startingSync: boolean;
  mapAttributes: MappAttributes;
  subsConfirmation: SubscriptionConfirmation;
  folders: Array<{ id: number; name: string }>;
  creatingList: boolean;
  folderId: number | null;
  folderName: string;
  listId: number | null;
  listName: string;
  savingList: boolean;
  finalTemplates: Array<{ id: number; name: string }>;
  doiTemplates: Array<{ id: number; name: string }>;
  savingOptIns: boolean;
  loadingOptIns: boolean;
  isResyncing: boolean;
  canResync: boolean;
};

export type ContactAttribute = {
  name: string;
  category?: string;
  type: string;
  field_key: string;
};

type MappAttributes = {
  autoAssign: boolean;
  contactMapping: Record<
    string,
    {
      display_name: string;
      mapped: boolean;
      mapped_to: string;
      type: string;
    }
  >;
  contactAttributes: {
    values: Array<ContactAttribute>;
    selection: Record<string, string>;
  };
  syncOption: string;
  loadingAttr: boolean;
  loadingMap: boolean;
  isSaving: boolean;
  newAttributeModal: number;
  isCreatingAttribute: boolean;
  listMapping: Array<{
    id: string;
    name: string;
    folderId: number;
    uniqueSubscribers: number;
    totalBlacklisted: number;
    totalSubscribers: number;
  }>;
  listItemsCount: number;
  loadingList: boolean;
  currentOffset: number;
};

type SubAccountReturnType = Pick<
  EmailModelState,
  'subAccountId' | 'onboardingStatus'
>;

const initialState: EmailModelState = {
  onboarding: {
    currentStep: EmailOnboardingStep.ABOUT_1,
    senderEmailVerificationSent: false,
    isDomainSuccessfullyAdded: false,
  },
  subAccountId: undefined,
  onboardingStatus: null,
  createdAt: '',
  isLoading: false,
  permissionURL: null,
  fraudDetection: {
    status: null,
    email: null,
    phone: null,
    failed_checks: null,
  },
  companyName: null,
  connectingBrevoAccount: false,
  fetchingAccountInfo: true,
  dataSyncStatus: {
    fetching: true,
    customer: null,
    order: null,
    product_variant: null,
    collection: null,
    product: null,
  },
  startingSync: false,
  connectionErrors: [],
  mapAttributes: {
    autoAssign: false,
    contactMapping: {},
    contactAttributes: {
      values: [],
      selection: {},
    },
    syncOption: 'list',
    loadingAttr: true,
    loadingMap: true,
    isSaving: false,
    newAttributeModal: null,
    isCreatingAttribute: false,
    listMapping: [],
    listItemsCount: 0,
    loadingList: false,
    currentOffset: 0,
  },
  subsConfirmation: {
    subs_conf: false,
    config: {
      is_conf_page: false,
      conf_page_url: null,
      email_conf_type: 'simple_confirmation',
      conf_template_id: null,
      final_conf_email: false,
      simple_conf_template_id: null,
      double_conf_template_id: null,
    },
    doi_allowed_tags: [],
  },
  folders: [],
  folderId: null,
  folderName: '',
  listName: '',
  listId: null,
  savingList: false,
  creatingList: false,
  finalTemplates: [],
  doiTemplates: [],
  savingOptIns: false,
  loadingOptIns: false,
  isResyncing: false,
  canResync: false,
};

const email = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    goToOnboardingStep(
      payload: {
        stepBy?: number;
        jumpTo?: EmailOnboardingStep;
        trackingDetails?: { event: string; properties: object };
      },
      rootState,
    ) {
      if (payload.trackingDetails && payload.trackingDetails.event) {
        posthog.capture(
          payload.trackingDetails.event,
          payload.trackingDetails.properties,
        );
      }

      if (typeof payload.jumpTo !== 'undefined') {
        dispatch.email.setOnboardingState({
          currentStep: payload.jumpTo % totalOnboardingSteps,
        });
        return;
      }

      if (typeof payload.stepBy !== 'undefined') {
        dispatch.email.setOnboardingState({
          currentStep:
            Math.abs(rootState.email.onboarding.currentStep + payload.stepBy) %
            totalOnboardingSteps,
        });
      }
    },

    async sendPhoneVerification(phoneNumber: string, rootState) {
      dispatch.email.setState({ isLoading: true });
      const { data, error } = await verifyPhoneNumber(
        phoneNumber,
        OnboardingSource.email,
      );

      if (
        typeof error === 'object' &&
        error?.error_type === 'PhoneVerificationFailed'
      ) {
        dispatch.saveToast.showError('phone_verification_error');
        this.getFraudDetection();
        dispatch.email.setState({ isLoading: false });
        return false;
      }

      if (
        typeof error === 'object' &&
        error?.error_type === 'SendingOTPFailed'
      ) {
        dispatch.saveToast.showError('sending_otp_failed');
        dispatch.email.setState({ isLoading: false });
        return false;
      }

      if (
        typeof error === 'object' &&
        error?.error_type === 'TooManySendAttempts'
      ) {
        dispatch.saveToast.showError('too_may_send_attempts');
        dispatch.email.setState({ isLoading: false });
        return false;
      }

      if (data.success) {
        dispatch.saveToast.showDone('phone_verification_success');
        dispatch.email.setState({ isLoading: false });
        return true;
      }

      dispatch.email.setState({
        isLoading: false,
        fraudDetection: {
          ...rootState.email.fraudDetection,
          phone: phoneNumber,
        },
      });
      return false;
    },

    async verifyOTP(payload: { phoneNumber: string; otp: Number }, rootState) {
      dispatch.email.setState({ isLoading: true });
      const { data, error } = await verifyOTP(payload.phoneNumber, payload.otp);

      if (typeof error === 'object' && error?.error_type === 'InvalidOTP') {
        dispatch.saveToast.showError('invalid_otp_provided');
        dispatch.email.setState({ isLoading: false });
      }

      if (
        typeof error === 'object' &&
        error?.error_type === 'AttemptLimitExceeded'
      ) {
        dispatch.saveToast.showError('attempt_limit_exceeded');
        this.setState({
          isLoading: false,
          onboarding: {
            ...rootState.email.onboarding,
            currentStep: EmailOnboardingStep.FRAUD_DETECTION_BLOCKED,
          },
        });
      }

      if (typeof error === 'object' && error?.error_type === 'OTPExpired') {
        dispatch.saveToast.showError('otp_expired');
        dispatch.email.setState({ isLoading: false });
      }

      if (data.success) {
        dispatch.saveToast.showDone('otp_verification_success');
        dispatch.email.setState({ isLoading: false });
        this.getFraudDetection();
        this.refetchSubAccount();
        return true;
      }

      dispatch.email.setState({ isLoading: false });
      return false;
    },

    async refetchSubAccount(): Promise<SubAccountReturnType> {
      const { data, error } = await fetchSubAccountAPI();

      let subAccount;
      if (data && !error) {
        subAccount = {
          subAccountId: data.sub_account_id,
          onboardingStatus: {
            dataSyncStatus: data.data_sync_status,
            domainVerificationStatus: data.domain_verification_status,
            senderVerificationStatus: data.sender_verification_status,
          },
          createdAt: data.created_at,
        };
        dispatch.email.setState(subAccount);
      }

      return { ...subAccount };
    },

    async getFraudDetection(_, rootState) {
      this.setState({
        isLoading: true,
      });

      const { data, error } = await getFraudDetectionAPI();

      if (error) {
        this.setState({
          isLoading: false,
        });
        return;
      }

      this.setState({
        permissionURL: data.permission_url,
        fraudDetection: {
          status: data.status,
          email: data.email,
          phone: data.phone,
          failed_checks: data.failed_checks,
        },
      });

      switch (data.status) {
        case 'block':
          this.setState({
            isLoading: false,
            onboarding: {
              ...rootState.email.onboarding,
              currentStep: EmailOnboardingStep.FRAUD_DETECTION_BLOCKED,
            },
          });
          break;
        case 'pass':
          await dispatch.pricingV3.fetchPricing({
            selectFreeEmailPlanByDefault: true,
          });
          this.setState({
            isLoading: false,
            onboarding: {
              ...rootState.email.onboarding,
              currentStep: EmailOnboardingStep.SYNC_DATA,
            },
          });
          break;
        case 'not_started':
          this.setState({
            isLoading: false,
            onboarding: {
              ...rootState.email.onboarding,
              currentStep: EmailOnboardingStep.ABOUT_1,
            },
          });
          break;
        case 'phone_verification_required':
          this.setState({
            isLoading: false,
            onboarding: {
              ...rootState.email.onboarding,
              currentStep: EmailOnboardingStep.VERIFY_PHONE_NUMBER,
            },
          });
          break;
        default:
          break;
      }

      if (!rootState.user?.user?.skipAnalytics) {
        try {
          FullStory('setProperties', {
            type: 'user',
            properties: { fraudDetectionStatus: data.status },
          });
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    },

    async submitFraudDetection(
      payload: { email: string; talon: string },
      rootState,
    ) {
      this.setState({
        isLoading: true,
      });
      const { data, error } = await submitFraudDetectionAPI({
        email: payload.email,
        talon: payload.talon,
      });
      this.setState({
        fraudDetection: {
          status: data.status,
          email: data.email,
          phone: data.phone,
          failed_checks: data.failed_checks,
        },
      });

      if (error) {
        return;
      }

      switch (data.status) {
        case 'pass':
          this.refetchSubAccount();
          await dispatch.pricingV3.fetchPricing({
            selectFreeEmailPlanByDefault: true,
          });
          this.setState({
            isLoading: false,
            onboarding: {
              ...rootState.email.onboarding,
              currentStep: EmailOnboardingStep.SYNC_DATA,
            },
          });
          break;
        case 'block':
          this.setState({
            isLoading: false,
            onboarding: {
              ...rootState.email.onboarding,
              currentStep: EmailOnboardingStep.FRAUD_DETECTION_BLOCKED,
            },
          });
          break;
        case 'pending':
          this.setState({
            isLoading: false,
            onboarding: {
              ...rootState.email.onboarding,
              currentStep: EmailOnboardingStep.FRAUD_DETECTION_PENDING,
            },
          });
          break;
        case 'not_started':
          this.setState({
            isLoading: false,
            onboarding: {
              ...rootState.email.onboarding,
              currentStep: EmailOnboardingStep.ABOUT_1,
            },
          });
          break;
        case 'phone_verification_required':
          this.setState({
            isLoading: false,
            onboarding: {
              ...rootState.email.onboarding,
              currentStep: EmailOnboardingStep.VERIFY_PHONE_NUMBER,
            },
          });
          break;
        default:
      }

      if (!rootState.user?.user?.skipAnalytics) {
        try {
          FullStory('setProperties', {
            type: 'user',
            properties: { fraudDetectionStatus: data.status },
          });
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    },

    async getSenders() {
      dispatch.email.setState({
        isLoading: true,
      });

      const { data, error } = await getSenderAPI();

      if (data && !error && data.senders) {
        const activeSender = data.senders.find(sender => sender.active);

        if (activeSender) {
          await dispatch.email.refetchSubAccount();
        }
      }

      dispatch.email.setState({
        isLoading: false,
      });
    },

    async getDomain() {
      dispatch.email.setState({
        isLoading: true,
      });
      const { data, error } = await fetchDomainAPI();

      if (data && !error && data.domain) {
        dispatch.email.setOnboardingState({ isDomainSuccessfullyAdded: true });
      }
      dispatch.email.setState({
        isLoading: false,
      });
    },

    async createDomain(payload: { domainName: string }) {
      dispatch.email.setState({
        isLoading: true,
      });
      const { data, error } = await createDomainAPI({
        domainName: payload.domainName,
      });

      if (data && !error) {
        dispatch.email.setOnboardingState({ isDomainSuccessfullyAdded: true });
      }

      if (error) {
        dispatch.saveToast.showError('adding_domain_create_error');
      }

      dispatch.email.setState({
        isLoading: false,
      });
    },

    async initiateDataSync() {
      posthog.capture('import data button clicked on 1st step', {
        step: 'data sync',
      });

      dispatch.email.setState({
        isLoading: true,
      });

      const { error } = await createMerchantTaskAPI({
        task: MerchantTask.DATA_SYNC_SHOPIFY_TO_BREVO,
      });

      if (error) {
        dispatch.saveToast.showError('data_sync_error_toast');
      } else {
        dispatch.email.goToOnboardingStep({
          jumpTo: EmailOnboardingStep.PRICING,
        });
        await dispatch.email.refetchSubAccount();
      }

      dispatch.email.setState({
        isLoading: false,
      });
    },

    async getDashboardRedirectURL(payload: string, rootState): Promise<string> {
      dispatch.email.setState({
        isLoading: true,
      });

      const {
        user: {
          website: {
            flags: { brevo_merchant },
          },
        },
      } = rootState.user;

      if (brevo_merchant === 'enabled') {
        dispatch.email.setState({
          isLoading: false,
        });
        return payload || 'https://app.brevo.com/';
      }

      const { data, error } = await fetchRedirectURLAPI(payload || '');

      let redirect_url = '';
      if (data && !error) {
        redirect_url = data.redirect_url;
      }

      dispatch.email.setState({
        isLoading: false,
      });

      return redirect_url;
    },

    async autoAssign(autoAssign: boolean) {
      dispatch.email.setState({ canResync: true });
      dispatch.email.setMapAttributes({ autoAssign });
      await dispatch.email.saveMapping(autoAssign);
    },

    isOnboardingCompleted(_, rootState): boolean {
      const { onboardingStatus } = rootState.email;
      const hasEmailPlan = dispatch.pricingV3.hasEmailPlan();

      if (!onboardingStatus) return false;

      return (
        onboardingStatus.dataSyncStatus === OnboardingStatus.COMPLETED &&
        onboardingStatus.senderVerificationStatus ===
          OnboardingStatus.COMPLETED &&
        hasEmailPlan
      );
    },

    async getCompanyName(temporaryToken: string) {
      const { data, error } = await getAccountInfoAPI(temporaryToken);
      dispatch.email.setState({
        fetchingAccountInfo: false,
      });

      const connectionErrors = [];

      if (error) {
        connectionErrors.push('error_fetching_brevo_account');

        dispatch.email.setState({
          connectionErrors,
        });

        return;
      }

      if (data && !error) {
        if (data.brevo_plugin_already_installed) {
          connectionErrors.push('brevo_plugin_installed');
        }

        if (
          !(
            data.application_flags.includes('automation') &&
            data.application_flags.includes('transactional-emails')
          )
        ) {
          connectionErrors.push('required_brevo_apps_not_enabled');
        }

        dispatch.email.setState({
          companyName: data.company_name,
          connectionErrors,
        });
      }
    },

    async connectBrevoAccount(temporaryToken: string) {
      dispatch.email.setState({
        connectingBrevoAccount: true,
      });
      const { data, error } = await connectBrevoAPI(temporaryToken);
      dispatch.email.setState({
        connectingBrevoAccount: false,
      });

      if (error) {
        if (typeof error !== 'string' && error.error_type) {
          dispatch.saveToast.showError(error.error_type);
        } else {
          dispatch.saveToast.showError('brevo_account_connection_error');
        }
        return;
      }

      if (data) {
        dispatch.email.setState({
          subAccountId: data.sub_account_obj_id,
        });
        Router.push('/integrations/brevo/3');
      }
    },

    async getBrevoSyncStatus() {
      dispatch.email.setState({
        dataSyncStatus: {
          fetching: true,
          customer: null,
          order: null,
          product_variant: null,
          collection: null,
          product: null,
        },
      });

      const { data, error } = await fetchDataSyncStatusAPI();

      if (error) {
        dispatch.email.setState({
          dataSyncStatus: {
            fetching: false,
            customer: null,
            order: null,
            product_variant: null,
            collection: null,
            product: null,
          },
        });

        dispatch.saveToast.showError('error_fetching_sync_status');
        return;
      }

      if (data) {
        dispatch.email.setState({
          dataSyncStatus: {
            fetching: false,
            customer: data.customer,
            order: data.order,
            product_variant: data.product_variant,
            collection: data.collection,
            product: data.product,
          },
        });
      }
    },
    async startDataSync() {
      dispatch.email.setState({
        startingSync: true,
      });

      const { error } = await startDataSyncAPI();

      dispatch.email.setState({
        startingSync: false,
      });

      if (error) {
        dispatch.saveToast.showError('data_sync_error_toast');
        return;
      }

      Router.push('/email');
    },
    async getContactMapping(_, rootState) {
      const { data: mappingData, error: getContactMappingError } =
        await getContactMappingAPI();
      const { data: attributeData, error: getContactAttributeError } =
        await getContactAttributesAPI();

      const { mapAttributes } = rootState.email;

      if (getContactMappingError || getContactAttributeError) {
        dispatch.email.setState({
          mapAttributes: {
            ...mapAttributes,
            loadingMap: false,
            contactMapping: {},
          },
        });
        dispatch.saveToast.showError('error_fetching_store_attributes');
        return;
      }

      const contactAttributeSelection = Object.keys(
        mappingData.contact_attributes,
      )
        .sort()
        .reduce((acc: Record<string, object>, curr: string, index: number) => {
          const attribute = mappingData.contact_attributes[curr];

          return {
            ...acc,
            [index]: !attribute.mapped ? DO_NOT_IMPORT : attribute.mapped_to,
          };
        }, {});

      dispatch.email.setState({
        mapAttributes: {
          ...mapAttributes,
          loadingMap: false,
          loadingAttr: false,
          autoAssign: mappingData.auto_assignment,
          contactMapping: mappingData.contact_attributes,
          contactAttributes: {
            values: attributeData.contact_attributes,
            selection: contactAttributeSelection,
          },
        },
      });
    },

    async getFolders() {
      const { data, error } = await getfoldersAPI();

      if (error) {
        dispatch.saveToast.showError('no_folders');
        return;
      }

      const folderId = data.folders.length > 0 ? data.folders[0].id : null;

      dispatch.email.setState({
        folders: data.folders,
        folderId,
      });
      dispatch.email.getListMapping({ offset: 0, folderId });
    },

    async getListMapping(
      payload: { offset: number; folderId: number | null },
      rootState,
    ) {
      const { mapAttributes } = rootState.email;

      dispatch.email.setState({
        mapAttributes: {
          ...mapAttributes,
          loadingList: true,
        },
      });

      const { data: listMappingData, error } = await getListMappingAPI(payload);

      if (listMappingData) {
        dispatch.email.setState({
          mapAttributes: {
            ...mapAttributes,
            listMapping: listMappingData.lists,
            listItemsCount: listMappingData.count,
            loadingList: false,
            currentOffset: payload.offset,
          },
        });
      }

      if (error) {
        dispatch.saveToast.showError('error_loading_list');
        dispatch.email.setState({
          mapAttributes: {
            ...mapAttributes,
            loadingList: false,
            listItemsCount: 0,
          },
        });
      }
    },

    async getListSelection() {
      const { data, error } = await getListSelectionAPI();
      if (error) {
        dispatch.saveToast.showError('error_fetcing_mapping');
      } else {
        dispatch.email.setState({
          folderName: data.folder.name,
          listName: data.list.name,
        });
      }
    },

    async saveMapping(autoAssign: boolean | null = null, rootState) {
      try {
        const { mapAttributes } = rootState.email;
        dispatch.email.setState({
          mapAttributes: {
            ...mapAttributes,
            isSaving: true,
          },
        });

        const contactAttributes = {};
        const getMapped = ({ newMapping, currentValue }) => {
          if (newMapping === DO_NOT_IMPORT) return false;

          return newMapping ? true : currentValue.mapped;
        };

        Object.keys(mapAttributes.contactMapping)
          .sort()
          .forEach((name, index) => {
            const currentValue = mapAttributes.contactMapping[name];

            const newMapping =
              mapAttributes.contactAttributes.selection[`${index}`];

            const attribute = mapAttributes.contactAttributes.values.find(
              item => item.name === newMapping,
            );

            contactAttributes[name] = {
              ...currentValue,
              type:
                newMapping && newMapping !== DO_NOT_IMPORT
                  ? attribute.type
                  : currentValue.type,
              mapped: getMapped({ newMapping, currentValue }),
              mapped_to: newMapping === DO_NOT_IMPORT ? '' : newMapping,
            };
          });

        const { error } = await saveContactMappingAPI({
          auto_assignment: autoAssign || mapAttributes.autoAssign,
          contact_attributes: contactAttributes,
        });
        dispatch.email.setState({
          mapAttributes: {
            ...mapAttributes,
            isSaving: false,
          },
        });

        if (error) {
          dispatch.saveToast.showError('failed_to_save');
        } else {
          dispatch.saveToast.showDone('saved_mapping_successfully');
          Router.push('/email');
        }
      } catch (error) {
        const { mapAttributes } = rootState.email;
        dispatch.email.setState({
          mapAttributes: {
            ...mapAttributes,
            isSaving: false,
          },
        });
        dispatch.saveToast.showError('failed_to_update');
        logErrorToSentry({ error });
      }
    },
    async createNewAttribute(
      payload: {
        name: string;
        categories: Array<string>;
        type: string;
      },
      rootState,
    ) {
      const { mapAttributes } = rootState.email;
      dispatch.email.setState({
        mapAttributes: {
          ...mapAttributes,
          isCreatingAttribute: true,
        },
      });

      const { error } = await createNewAttributeAPI(payload);

      if (error) {
        dispatch.email.setState({
          mapAttributes: {
            ...mapAttributes,
            isCreatingAttribute: false,
          },
        });
        if (
          typeof error === 'object' &&
          error.error_type === 'ContactAttributeCreationFailed'
        ) {
          dispatch.saveToast.showError('name_not_unique');
        } else {
          dispatch.saveToast.showError('failed_to_create_attribute');
        }
      } else {
        const { newAttributeModal } = mapAttributes;

        dispatch.email.setState({
          mapAttributes: {
            ...mapAttributes,
            isCreatingAttribute: false,
            newAttributeModal: null,
            contactAttributes: {
              values: [
                ...mapAttributes.contactAttributes.values,
                {
                  name: payload.name,
                  type: payload.type,
                  field_key: payload.name,
                },
              ],
              selection: {
                ...mapAttributes.contactAttributes.selection,
                [newAttributeModal]: payload.name,
              },
            },
          },
        });
        dispatch.saveToast.showDone('created_new_attribute');
      }
    },

    async createNewList(payload: { name: string; folder_id: number }) {
      dispatch.email.setState({ creatingList: true });
      const { error } = await createNewListAPI(payload);
      dispatch.email.setState({ creatingList: false });

      if (error) {
        dispatch.saveToast.showError('error_creating_new_list');
      } else {
        dispatch.saveToast.showDone('created_a_new_list');
      }
    },

    async saveList(_, rootState) {
      dispatch.email.setState({ savingList: true });
      const { email } = rootState;
      const { error } = await saveListAPI({
        list_id: email.listId,
        folder_id: email.folderId,
      });
      dispatch.email.setState({ savingList: false });

      if (error) {
        dispatch.saveToast.showError('error_saving_list');
      } else {
        dispatch.saveToast.showDone('saved_list_successfully');
        Router.push('/email');
      }
    },

    async getOptIns(_, rootState) {
      const {
        user: {
          user: {
            website: { domain },
          },
        },
      } = rootState;

      dispatch.email.setState({ loadingOptIns: true });

      const { data, error } = await getOptInsAPI();
      const { data: finalTemplates, error: errorFinal } =
        await getFinalTemplatesAPI();
      const { data: doubleTemplates, error: errorDouble } =
        await getDoubleTemplatesAPI();

      dispatch.email.setState({ loadingOptIns: false });

      const finalTemplateId = finalTemplates.templates[0]?.id;
      const doubleTemplateId = doubleTemplates.templates[0]?.id;

      if (error || errorFinal || errorDouble) {
        dispatch.saveToast.showError('error_loading_opt_ins');
      } else {
        const {
          simple_final_confirmation_template_id,
          confirmation_template_id,
          double_final_confirmation_template_id,
        } = data.config;

        dispatch.email.setState({
          finalTemplates: finalTemplates.templates,
          doiTemplates: doubleTemplates.templates,
          subsConfirmation: {
            subs_conf: data.subscription_confirmation,
            config: {
              is_conf_page: data.config.is_confirmation_page || false,
              conf_page_url: data.config.confirmation_page_url || domain,
              email_conf_type:
                data.config.email_confirmation_type || 'simple_confirmation',
              final_conf_email:
                data.config.is_double_final_confirmation_email || false,
              simple_conf_template_id:
                simple_final_confirmation_template_id || finalTemplateId,
              conf_template_id: confirmation_template_id || doubleTemplateId,
              double_conf_template_id:
                double_final_confirmation_template_id || finalTemplateId,
            },
            doi_allowed_tags: data.doi_allowed_tags || [],
          },
        });
      }
    },

    async saveOptIns(_, rootState) {
      const { email } = rootState;

      dispatch.email.setState({ savingOptIns: true });

      const payload = {
        subscription_confirmation: email.subsConfirmation.subs_conf,
        config: {
          email_confirmation_type:
            email.subsConfirmation.config.email_conf_type,
          confirmation_template_id:
            email.subsConfirmation.config.conf_template_id,
          is_confirmation_page: email.subsConfirmation.config.is_conf_page,
          confirmation_page_url: email.subsConfirmation.config.conf_page_url,
          is_double_final_confirmation_email:
            email.subsConfirmation.config.final_conf_email,
          simple_final_confirmation_template_id:
            email.subsConfirmation.config.simple_conf_template_id,
          double_final_confirmation_template_id:
            email.subsConfirmation.config.double_conf_template_id,
        },
        doi_allowed_tags: email.subsConfirmation.doi_allowed_tags,
      };

      const { error } = await saveOptInsAPI(payload);

      dispatch.email.setState({ savingOptIns: false });

      if (error) {
        dispatch.saveToast.showError('could_not_save_opt_ins');
      } else {
        dispatch.saveToast.showDone('saved_opt_ins');
      }
    },
    async resync() {
      dispatch.email.setState({ isResyncing: true });
      const { error } = await resyncAPI();
      dispatch.email.setState({ isResyncing: false });
      if (error) {
        dispatch.saveToast.showError('resync_failed');
      } else {
        dispatch.saveToast.showDone('resync_successful');
        dispatch.email.setState({ canResync: false });
      }
    },
  }),

  reducers: {
    setOnboardingState(state, payload: Partial<EmailModelState['onboarding']>) {
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          ...payload,
        },
      };
    },

    setMapAttributes(state, payload: Partial<MappAttributes>) {
      return {
        ...state,
        canResync: true,
        mapAttributes: {
          ...state.mapAttributes,
          ...payload,
        },
      };
    },

    setState(state, payload: Partial<EmailModelState>) {
      return {
        ...state,
        ...payload,
      };
    },

    setSubscription(state, payload: Partial<SubscriptionConfirmation>) {
      return {
        ...state,
        subsConfirmation: {
          ...state.subsConfirmation,
          ...payload,
        },
      };
    },

    setSubscriptionConfig(
      state,
      payload: Partial<SubscriptionConfirmation['config']>,
    ) {
      return {
        ...state,
        subsConfirmation: {
          ...state.subsConfirmation,
          config: {
            ...state.subsConfirmation.config,
            ...payload,
          },
        },
      };
    },

    setDOIAllowedTags(state, payload: string[]) {
      return {
        ...state,
        subsConfirmation: {
          ...state.subsConfirmation,
          doi_allowed_tags: payload,
        },
      };
    },
  },
});

export default email;
