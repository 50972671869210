import {
  BlockStack,
  Box,
  Card,
  Link,
  Text,
  useBreakpoints,
} from '@shopify/polaris';
import { format } from 'date-fns';

const getMaintenanceEndsAt = () => {
  try {
    const maintenanceEndsAt = process.env.NEXT_PUBLIC_MAINTENANCE_ENDS_AT;
    if (!maintenanceEndsAt) {
      return null;
    }
    return new Date(maintenanceEndsAt);
  } catch (error) {
    return null;
  }
};

export const MaintenancePage = () => {
  const { smDown } = useBreakpoints();
  const maintenanceEndsAt = getMaintenanceEndsAt();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Card>
        <Box padding='800' width={smDown ? '80vw' : '40vw'} minWidth='100%'>
          <BlockStack inlineAlign='center' gap='400'>
            <img
              src='/static/svg/MaintenanceOwly.svg'
              alt='maintenance owly'
              width='200px'
            />
            <Text variant='headingXl' as='h4' alignment='center'>
              We are currently under maintenance
            </Text>
            {maintenanceEndsAt ? (
              <Text as='p' variant='bodyLg' tone='subdued' alignment='center'>
                We will be back by <b>{format(maintenanceEndsAt, 'PPp')}</b>
              </Text>
            ) : (
              <Text as='p' variant='bodyMd' tone='subdued' alignment='center'>
                We will be back soon!
              </Text>
            )}
          </BlockStack>
        </Box>
      </Card>
      <Text as='p' variant='bodyLg'>
        <Link url='https://status.pushowl.com'>Status Page</Link>
      </Text>
    </div>
  );
};
