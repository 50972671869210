import { FormWidgetStep } from 'src/modules/optins/components/OptinFlowEditor/FormWidgetEditorAddon/AIEditor/models';
import { UserPrompt } from 'src/modules/optins/models/types';
import { del, get, post } from './axios';

export function getAIResponseAPI({
  prompt,
  code,
  flowNodeId,
  step,
  temperature = 1,
}: {
  prompt: UserPrompt;
  code: string;
  flowNodeId: number;
  step: FormWidgetStep;
  temperature?: number;
}) {
  return post('/optins/ai/', {
    code,
    prompt: prompt.prompt,
    imgURL: prompt.imageURL ?? '',
    flowNodeId,
    temperature,
    step,
  });
}

export function getConversationHistoryAPI({
  flowNodeId,
}: {
  flowNodeId: number;
}) {
  return get(`/optins/ai/${flowNodeId}`);
}

export function deleteConversationHistoryAPI({
  flowNodeId,
}: {
  flowNodeId: number;
}) {
  return del(`/optins/ai/${flowNodeId}`);
}
