import { ChannelDisplayName } from 'src/lib/constants';
import { FormWidgetStep } from 'src/modules/optins/components/OptinFlowEditor/FormWidgetEditorAddon/AIEditor/models';

export const script = selectedNodeName =>
  selectedNodeName === ChannelDisplayName.sms.toLowerCase()
    ? `<!-- Paste your HTML and CSS code here to create a custom opt-in form or view a sample -->
  <!DOCTYPE html>
  <html lang="en">
  
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>SMS Opt-In Form</title>
      <style>
          /* Reset margins and padding for body and html to avoid unintended spacing */
          body,
          html {
              margin: 0;
              padding: 0;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #f0f0f0; /* Light gray background for entire page */
              font-family: Arial, sans-serif; /* Use a clean, standard font */
          }
  
          /* Style for the card container holding the entire opt-in form */
          .card {
              position: relative; /* Allows positioning of the close button */
              background-color: #ffffff; /* White background for the card */
              border-radius: 8px; /* Rounded corners for the card */
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Soft shadow for a 3D effect */
              width: 300px; /* Fixed width for the opt-in form */
              overflow: hidden; /* Keep the contents within the border radius */
          }
  
          /* Style for the image part of the card */
          .card-image {
              width: 100%; /* Image should cover the entire width of the card */
              height: 150px; /* Control the height of the image block */
              background-image: url('https://s3.amazonaws.com/upload.pushowl.com/prodfire/default-8982d997-de3a-428a-a0d0-e0ca72ff8192-images3.jpeg'); /* Background image */
              background-size: cover; /* Cover the entire area without losing aspect ratio */
              background-position: center; /* Center the image */
              background-repeat: no-repeat; /* Prevent background image from repeating */
          }
  
          /* Close button styling */
          .close-button {
              position: absolute;
              top: 10px;
              right: 10px; /* Position the button in the upper-right corner */
               background: #f0f0f0; /* Commented out to remove the background */
              border: none;
              font-size: 18px;
              cursor: pointer;
          }
  
          /* Style for the content part of the card */
          .card-content {
              padding: 20px; /* Padding around the inner contents of the card */
          }
  
          /* Style for the heading inside the card */
          .card h2 {
              margin: 0 0 10px; /* No margin at the top, some margin at the bottom */
              text-align: center; /* Center-align the heading */
              font-size: 18px; /* Font size for the heading */
              color: #333; /* Dark gray color for readability */
          }
  
          /* Style for the paragraph/description inside the card */
          .card p {
              margin: 0 0 15px; /* No margin at the top, some margin at the bottom */
              text-align: center; /* Center-align the paragraph */
              font-size: 12px; /* Smaller font size for the description */
              color: #666; /* Lighter gray to differentiate from the heading */
          }
  
          /* Form wrapper styling */
          .card form {
              display: flex; /* Use flexbox for form elements */
              flex-direction: column; /* Stack input and button vertically */
          }
  
          /* Style for the input field */
          .card input {
              margin-bottom: 10px; /* Space between input and the button */
              padding: 8px; /* Padding inside the input box */
              border: 1px solid #ddd; /* Light gray border for the input */
              border-radius: 4px; /* Rounded edges for the input box */
              font-size: 14px; /* Font size inside the input box */
          }
  
          /* Style for the submit button */
          .card button {
              padding: 8px; /* Padding for the button */
              background-color: #4CAF50; /* Green background to attract attention */
              color: white; /* White text color for contrast */
              border: none; /* Remove any default button borders */
              border-radius: 4px; /* Apply rounded edges to the button */
              cursor: pointer; /* Change the cursor to a pointer on hover */
              font-size: 14px; /* Font size for the button text */
          }
  
          /* Style for the message that appears after form submission */
          #message {
              margin-top: 10px; /* Space between form and the message */
              text-align: center; /* Center the message text */
              font-size: 12px; /* Smaller font size for the message */
          }

          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
          }

          /* Firefox */
          input[type=number] {
          -moz-appearance: textfield;
          }
      </style>
  </head>
  
  <body>
  
      <div class="card">
  
          <button id="close-button" class="close-button">&times;</button>
  
          <div class="card-image"></div>
  
          <div class="card-content">
  
              <h2>Subscribe to Our Newsletter</h2>
  
              <p>Stay updated with our latest news and offers!</p>
  
              <form id="opt-in-form">
  
                  <input type='number' id='phone_number' placeholder="+55555555" required>
  
                  <button id="Submit button" type="submit">Subscribe</button>
              </form>
          </div>
      </div>
  </body>
  
  </html>`
    : `<!-- Paste your HTML and CSS code here to create a custom opt-in form or view a sample -->
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Email Opt-In Form</title>
    <style>
        /* Reset margins and padding for body and html to avoid unintended spacing */
        body,
        html {
            margin: 0;
            padding: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f0f0f0;
            /* Light gray background for entire page */
            font-family: Arial, sans-serif;
            /* Use a clean, standard font */
        }

        /* Style for the card container holding the entire opt-in form */
        .card {
            position: relative;
            /* Allows positioning of the close button */
            background-color: #ffffff;
            /* White background for the card */
            border-radius: 8px;
            /* Rounded corners for the card */
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            /* Soft shadow for a 3D effect */
            width: 300px;
            /* Fixed width for the opt-in form */
            overflow: hidden;
            /* Keep the contents within the border radius */
        }

        /* Style for the image part of the card */
        .card-image {
            width: 100%;
            /* Image should cover the entire width of the card */
            height: 150px;
            /* Control the height of the image block */
            background-image: url('https://s3.amazonaws.com/upload.pushowl.com/prodfire/default-8982d997-de3a-428a-a0d0-e0ca72ff8192-images3.jpeg');
            /* Background image */
            background-size: cover;
            /* Cover the entire area without losing aspect ratio */
            background-position: center;
            /* Center the image */
            background-repeat: no-repeat;
            /* Prevent background image from repeating */
        }

        /* Close button styling */
        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            /* Position the button in the upper-right corner */
            background: transparent;
            border: none;
            font-size: 18px;
            cursor: pointer;
        }

        /* Style for the content part of the card */
        .card-content {
            padding: 20px;
            /* Padding around the inner contents of the card */
        }

        /* Style for the heading inside the card */
        .card h2 {
            margin: 0 0 10px;
            /* No margin at the top, some margin at the bottom */
            text-align: center;
            /* Center-align the heading */
            font-size: 18px;
            /* Font size for the heading */
            color: #333;
            /* Dark gray color for readability */
        }

        /* Style for the paragraph/description inside the card */
        .card p {
            margin: 0 0 15px;
            /* No margin at the top, some margin at the bottom */
            text-align: center;
            /* Center-align the paragraph */
            font-size: 12px;
            /* Smaller font size for the description */
            color: #666;
            /* Lighter gray to differentiate from the heading */
        }

        /* Form wrapper styling */
        .card form {
            display: flex;
            /* Use flexbox for form elements */
            flex-direction: column;
            /* Stack input and button vertically */
        }

        /* Style for the input field */
        .card input {
            margin-bottom: 10px;
            /* Space between input and the button */
            padding: 8px;
            /* Padding inside the input box */
            border: 1px solid #ddd;
            /* Light gray border for the input */
            border-radius: 4px;
            /* Rounded edges for the input box */
            font-size: 14px;
            /* Font size inside the input box */
        }

        /* Style for the submit button */
        .card button {
            padding: 8px;
            /* Padding for the button */
            background-color: #4CAF50;
            /* Green background to attract attention */
            color: white;
            /* White text color for contrast */
            border: none;
            /* Remove any default button borders */
            border-radius: 4px;
            /* Apply rounded edges to the button */
            cursor: pointer;
            /* Change the cursor to a pointer on hover */
            font-size: 14px;
            /* Font size for the button text */
        }

        /* Style for the message that appears after form submission */
        #message {
            margin-top: 10px;
            /* Space between form and the message */
            text-align: center;
            /* Center the message text */
            font-size: 12px;
            /* Smaller font size for the message */
        }
    </style>
</head>

<body>

    <div class="card">
        <button id ="close-button" class="close-button">&times;</button>

        <div class="card-image"></div>

        <div class="card-content">

            <h2>Subscribe to Our Newsletter</h2>

            <p>Stay updated with our latest news and offers!</p>

            <form id="opt-in-form">

                <input type='email' id='email' placeholder="Enter your email" required>

                <button id="button" type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</body>

</html>`;

export const thankYouScript = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Thank you Form</title>
    <style>
        /* Reset margins and padding for body and html to avoid unintended spacing */
        body,
        html {
            margin: 0;
            padding: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f0f0f0;
            /* Light gray background for entire page */
            font-family: Arial, sans-serif;
            /* Use a clean, standard font */
        }

        /* Style for the card container holding the entire opt-in form */
        .card {
            position: relative;
            /* Allows positioning of the close button */
            background-color: #ffffff;
            /* White background for the card */
            border-radius: 8px;
            /* Rounded corners for the card */
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            /* Soft shadow for a 3D effect */
            width: 300px;
            /* Fixed width for the opt-in form */
            overflow: hidden;
            /* Keep the contents within the border radius */
        }

        /* Style for the image part of the card */
        .card-image {
            width: 100%;
            /* Image should cover the entire width of the card */
            height: 150px;
            /* Control the height of the image block */
            background-image: url('https://s3.amazonaws.com/upload.pushowl.com/prodfire/default-8982d997-de3a-428a-a0d0-e0ca72ff8192-images3.jpeg');
            /* Background image */
            background-size: cover;
            /* Cover the entire area without losing aspect ratio */
            background-position: center;
            /* Center the image */
            background-repeat: no-repeat;
            /* Prevent background image from repeating */
        }

        /* Close button styling */
        .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            /* Position the button in the upper-right corner */
            background: transparent;
            border: none;
            font-size: 18px;
            cursor: pointer;
        }

        /* Style for the content part of the card */
        .card-content {
            padding: 20px;
            /* Padding around the inner contents of the card */
        }

        /* Style for the heading inside the card */
        .card h1 {
            margin: 0 0 24px;
            /* No margin at the top, some margin at the bottom */
            text-align: left;
            /* Center-align the heading */
            font-size: 30px;
            /* Font size for the heading */
            color: #333;
            /* Dark gray color for readability */
        }

        /* Style for the paragraph/description inside the card */
        .card p {
            margin: 0 0 15px;
            /* No margin at the top, some margin at the bottom */
            text-align: left;
            /* Center-align the paragraph */
            font-size: 12px;
            /* Smaller font size for the description */
            color: #666;
            /* Lighter gray to differentiate from the heading */
        }
    </style>
</head>

<body>

    <div class="card">
        <button id ="close-button" class="close-button">&times;</button>
        <div class="card-image"></div>
        <div class="card-content">
            <h1>You're all set!</h1>
            <p>Thank you for signing up.</p>
        </div>
    </div>
</body>

</html>`;

export const getPredefinedPrompts = ({
  selectedChannel = '',
  unSelectedChannel = '',
  formWidgetStep = FormWidgetStep.Step1,
}: {
  selectedChannel?: string;
  unSelectedChannel?: string;
  formWidgetStep?: FormWidgetStep;
} = {}) => [
  {
    prompt: 'Edit this opt-in form to match my brand.',
    promptForAI:
      'these are the brand colors, use these in the optin form to make it more personalized, use the colors for things like background of buttons, text color, etc.',
    id: 'edit_brand' as const,
  },
  {
    prompt: 'Add my logo to this opt-in form for my branding',
    promptForAI:
      'this is the logo of the store, use this in the optin form to make it more personalized',
    id: 'add_logo' as const,
  },
  {
    prompt: `Use ${unSelectedChannel} ${
      formWidgetStep === FormWidgetStep.Step1 ? 'opt-in' : 'thank you form'
    } as a reference`,
    promptForAI: `if the code has inputs, then change all inputs, buttons and text related to ${unSelectedChannel} for an optin form that is used to collect ${selectedChannel} data. Otherwise just copy the styles and layout.`,
    id: 'use_reference' as const,
  },
];

export const AIModelErrors = (error: string) => {
  return `
    <div style="background:white; color:black; height:100%; width:100%; border-radius:12px; display:flex; align-items:center; justify-content:center; font-family:var(--p-text-heading-3xl-font-family);"}}>
    <p>${error}</p>
    </div>
    `;
};
