import { BlockStack, Box, Button, InlineStack, Text } from '@shopify/polaris';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { useGlobalState } from 'src/hooks/use-global-state';
import { Modal, ModalContent, ModalOverlay } from './styled';

export const ReInstallModal = () => {
  const { t } = useTranslation('trial');
  const {
    state: { orderCount },
  } = useGlobalState('integratedDashboard');
  const router = useRouter();
  const { reinstall: reInstall, trial } = router.query as {
    reinstall?: string;
    trial?: string;
  };
  const posthog = usePostHog();

  const handleTrial = () => {
    posthog.capture('Re-install primary CTA Clicked');
    if (orderCount < 2000) {
      router.push('/?trial=19&reinstall=true');
    } else if (orderCount > 2000 && orderCount < 10_000) {
      router.push('/?trial=38&reinstall=true');
    } else if (orderCount > 10_000) {
      router.push('/?trial=79&reinstall=true');
    }
  };

  if (reInstall && !trial) {
    return (
      <>
        <ModalOverlay />
        <Modal>
          <ModalContent>
            <InlineStack gap='500'>
              <img
                src='/static/img/reinstall.svg'
                style={{
                  width: '323px',
                  height: '280px',
                }}
                alt={t('welcome_back')}
              />
              <Box maxWidth='32ch'>
                <BlockStack gap='2800'>
                  <BlockStack gap='150'>
                    <Text as='h2' variant='headingXl' fontWeight='semibold'>
                      {t('welcome_back')}
                    </Text>
                    <Text as='p' variant='bodyLg'>
                      {t('reinstall_description')}
                    </Text>
                  </BlockStack>
                  <Button
                    onClick={handleTrial}
                    size='large'
                    variant='primary'
                    tone='success'
                  >
                    {t('get_started')}
                  </Button>
                </BlockStack>
              </Box>
            </InlineStack>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return null;
};
