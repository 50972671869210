import { createModel } from '@rematch/core';
import Router from 'next/router';
import { LockedLogoImage } from 'src/constants/lockedLogoImage';
import { insertNewKeys } from 'src/hooks/use-auth';
import {
  fetchShopifyMarketingAutomationAPI,
  postCampaignAPI,
  putCampaignAPI,
  saveShopifyMarketingAutomationAPI,
  validateDiscountCodeAPI,
} from 'src/lib/api/campaigns';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { getStorage } from 'src/lib/storage';
import { isLogoLocked } from 'src/lib/utils';
import store from 'src/store';
import { RootModel } from 'src/store/models';
import { v1 as uuidv1 } from 'uuid';

type Time = null | Date;

export enum SendType {
  SCHEDULED = 'scheduled',
  NOW = 'now',
}

export enum FunctionMode {
  Create = 'create',
  Edit = 'edit',
}

export enum CampaignType {
  Regular = 'regular',
  FlashSale = 'flash',
}

export type DiscountCodeValidity = {
  type?: 'success' | 'failure' | 'warning';
  message: string | null;
  isChecking: boolean;
};

export enum MarketingAutomationType {
  ProductRelease = 'product_release',
}

export interface CampaignCreatorState {
  showSendConfirmation: boolean;
  id: number;
  automation_step_type?: MarketingAutomationType;
  mode: FunctionMode;
  isCreated: boolean;
  isCreating: boolean;
  inProgress: boolean;
  isDraft: boolean;
  isSavingDraft: boolean;
  isFetchingAutomation: boolean;
  campaignType: CampaignType;
  source: string | null;
  segment: {
    id: any;
    name: string;
    count: number;
  };
  sendType: 'now' | 'scheduled';
  scheduledTime: Time;
  endCampaignTime: Time;
  smartDelivery: boolean;
  error: string;
  discountCodeValidity: DiscountCodeValidity;
  templateId?: string;
}

const initialState = (): CampaignCreatorState => ({
  showSendConfirmation: false,
  mode: FunctionMode.Create,
  id: null,
  isCreated: false,
  isCreating: false,
  inProgress: true,
  campaignType: CampaignType.Regular,
  segment: null,
  isDraft: false,
  isSavingDraft: false,
  isFetchingAutomation: true,
  sendType: 'now',
  scheduledTime: null,
  endCampaignTime: null,
  smartDelivery: false,
  error: null,
  discountCodeValidity: {
    message: null,
    isChecking: false,
  },
  templateId: null,
  source: null,
});

const campaignCreator = createModel<RootModel>()({
  state: initialState(),

  reducers: {
    setState(state, payload: any) {
      return {
        ...state,
        ...payload,
      };
    },
    start(
      state: CampaignCreatorState,
      payload: Partial<CampaignCreatorState> | undefined,
    ) {
      return {
        ...state,
        inProgress: true,
        ...(payload || {}),
      };
    },

    shut() {
      return initialState();
    },

    setConfirmationModalVisibility(
      state: CampaignCreatorState,
      showSendConfirmation: boolean,
    ) {
      return {
        ...state,
        showSendConfirmation,
      };
    },

    clearDiscountCodeValidation(state: CampaignCreatorState) {
      return {
        ...state,
        discountCodeValidity: {
          message: null,
          isChecking: false,
        },
      };
    },

    loadState(state: CampaignCreatorState, payload: AnyObject) {
      return {
        ...state,
        ...payload,
      };
    },

    setProp(
      state: CampaignCreatorState,
      payload: { prop: string; value: any },
    ) {
      return {
        ...state,
        [payload.prop]: payload.value,
      };
    },
  },

  effects: {
    async setCampaignErrorState({
      isDraft,
      error,
    }: {
      isDraft: boolean;
      error: string;
    }) {
      if (isDraft)
        this.setState({
          isSavingDraft: false,
          error: 'Changes not saved',
        });
      else
        this.setState({
          isCreating: false,
          error,
        });
    },

    async createCampaign(
      payload: { isDraft: boolean } = { isDraft: true },
      rootState,
    ) {
      try {
        const { campaignCreator: campaign, notificationCreator: notification } =
          rootState;

        if (!campaign.segment) return;

        const { isDraft } = payload;

        // ttl for campaign run
        let ttl = 2419200; /* seconds in 28 days */
        const sendDate =
          campaign.sendType === 'scheduled'
            ? new Date(campaign.scheduledTime)
            : new Date();

        if (campaign.endCampaignTime)
          ttl =
            (new Date(campaign.endCampaignTime).getTime() -
              sendDate.getTime()) /
            1000;

        const user = insertNewKeys(getStorage().get('user'));
        const { orderCount } = rootState.integratedDashboard;

        const requestData = {
          ttl: parseInt(ttl.toFixed(0), 10),
          title: notification.title,
          description: notification.message,
          redirect_url: notification.primaryLink,
          discount_code: notification.discountCode || null,
          image: notification.heroImage.desktop,
          mobile_image: notification.heroImage.mobile,
          images: {
            // setting store logo for mac if hero image is not there
            mac_image: (
              user?.website?.website_plan || user?.website?.current_plan
            )?.enable_big_image
              ? notification.heroImage.macos || notification.icon
              : notification.heroImage.macos,
          },
          smart_delivery: campaign.smartDelivery,
          segment_id: campaign.segment.id,
          scheduled_time: campaign.scheduledTime
            ? Math.trunc(new Date(campaign.scheduledTime).getTime() / 1000)
            : null,
          end_campaign_time: campaign.endCampaignTime
            ? Math.trunc(new Date(campaign.endCampaignTime).getTime() / 1000)
            : null,
          actions: notification.buttons.map(button => ({
            title: button.title,
            redirect_url: button.link,
          })),
          icon: isLogoLocked({ orderCount, user })
            ? LockedLogoImage
            : notification.icon,
          status: 'draft',
          template_id:
            rootState.notificationCreator.campaignTemplates
              ?.lastAppliedTemplateId || campaign.templateId,
          source: campaign.source,
        };

        // If not a draft campaign change status to active
        if (!isDraft) requestData.status = 'active';

        // if it is a draft campaign and saving is in progress firs time we prevent subsequent calls
        // to prevent duplicate draft campaign
        if (isDraft && campaign.isSavingDraft && !campaign.id) return;

        if (isDraft) {
          this.setState({ isDraft: true, isSavingDraft: true, error: null });
        } else {
          this.setState({ isCreating: true });
        }

        const res = await (campaign.id
          ? putCampaignAPI(campaign.id, requestData)
          : postCampaignAPI(requestData));

        if (!res.error) {
          if (!isDraft) {
            this.setState({ isCreated: true });
            const campaignStatus =
              campaign.sendType === 'scheduled' ? 'scheduled' : 'sent';
            store.dispatch.campaignCreator.shut();
            store.dispatch.notificationCreator.shut();
            Router.push(`/campaigns?tab=sent&currentStatus=${campaignStatus}`);
          } else {
            this.setState({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              id: res.data.id,
              isDraft: true,
              isSavingDraft: false,
            });
          }

          return;
        }

        this.setCampaignErrorState({ isDraft, error: res.error });
        store.dispatch.saveToast.showError('Error sending campaign');
      } catch (error) {
        this.setCampaignErrorState({
          isDraft: payload.isDraft,
          error: 'Error sending campaign',
        });
        store.dispatch.campaignCreator.setConfirmationModalVisibility(false);

        logErrorToSentry({
          error: 'Campaign creation error',
          extras: {
            error,
          },
        });
      }
    },

    async saveShopifyMarketingAutomation(_, rootState) {
      const { campaignCreator: campaign, notificationCreator: notification } =
        rootState;

      this.setState({ isCreating: true });
      const storage = getStorage();
      const user = insertNewKeys(storage.get('user'));

      const requestData = {
        step_reference: storage.get('step_reference'),
        title: notification.title,
        description: notification.message,
        redirect_url: notification.primaryLink,
        discount_code: notification.discountCode || null,
        image: notification.heroImage.desktop,
        mobile_image: notification.heroImage.mobile,
        images: {
          // setting store logo for mac if hero image is not there
          mac_image: (user.website.website_plan || user.website.current_plan)
            .enable_big_image
            ? notification.heroImage.macos || notification.icon
            : notification.heroImage.macos,
        },
        actions: notification.buttons.map(button => ({
          title: button.title,
          redirect_url: button.link,
        })),
        icon: notification.icon,
        template_id:
          rootState.notificationCreator.campaignTemplates
            ?.lastAppliedTemplateId || campaign.templateId,
      };

      const res = await saveShopifyMarketingAutomationAPI(requestData);
      this.setState({ isCreating: false });
      store.dispatch.saveToast.showDone('Changes saved');

      if (res.error) {
        store.dispatch.saveToast.showError('Error saving changes');
      }
    },

    async fetchShopifyMarketingAutomation() {
      store.dispatch.notificationCreator.shut();
      this.setState({
        isFetchingAutomation: true,
      });
      const storage = getStorage();
      const stepReference = storage.get('step_reference');
      const res = await fetchShopifyMarketingAutomationAPI(stepReference);
      if (res.error) {
        store.dispatch.saveToast.showError('Error fetching settings');
      } else {
        if (Object.keys(res.data).length === 0) {
          store.dispatch.notificationCreator.shut();
          this.setState({ isFetchingAutomation: false });
          return;
        }

        const {
          title,
          description,
          redirect_url,
          discount_code,
          template_id,
          icon,
          image,
          images,
          mobile_image,
          actions,
          automation_step_type,
        } = res.data;

        const buttons = (actions || []).map(action => ({
          title: action.title,
          link: action.redirect_url,
          id: uuidv1(),
        }));

        store.dispatch.notificationCreator.loadState({
          title,
          message: description,
          primaryLink: redirect_url,
          discountCode: discount_code,
          icon,
          heroImage: {
            desktop: image,
            mobile: mobile_image,
            macos: images.mac_image,
          },
          buttons,
        });

        this.setState({
          templateId: template_id,
          automation_step_type,
        });
      }

      this.setState({ isFetchingAutomation: false });
    },

    async validateDiscountCode(discountCode: string) {
      this.setState({
        discountCodeValidity: { message: null, isChecking: true },
      });

      const res = await validateDiscountCodeAPI(discountCode);

      if (!res.error) {
        this.setState({
          discountCodeValidity: { ...res.data, isChecking: false },
        });
        return;
      }

      this.setState({
        discountCodeValidity: {
          type: 'warning',
          message: 'Unable to validate discount code',
          isChecking: false,
        },
      });
    },
  },
});

export default campaignCreator;
