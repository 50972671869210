import { isBefore, startOfDay } from 'date-fns';


export const formatChecklistItems = checklist => {
  return checklist.map(checklistItem => ({
    id: checklistItem.id,
    title: checklistItem.title,
    description: checklistItem.description,
    primaryButtonUrl: checklistItem.primary_button_url,
    primaryButtonText: checklistItem.primary_button_text,
    isCtaPrimary: checklistItem.is_cta_primary,
    status: checklistItem.status,
    checklistId: checklistItem.checklist_id,
    checklistTitle: checklistItem.checklist_title,
    isChecklistItem: true,
    start: checklistItem.start_date,
    end: checklistItem.end_date,
    allDay: true,
    isFestival: false,
    isSent: false,
    isCampaign: false,
    referenceUrl: checklistItem.reference_url,
    priority: 2,
  }));
};

export const formatWebpushCampaigns = campaigns => {
  if (Object.keys(campaigns).length === 0) {
    return [];
  }

  return campaigns.map(campaign => ({
    campaignDetails: { ...campaign },
    title: campaign.title,
    start: campaign.scheduled_time
      ? new Date(campaign.scheduled_time * 1000).toISOString()
      : campaign.dispatched_time,
    end: campaign.scheduled_time
      ? new Date(campaign.scheduled_time * 1000).toISOString()
      : campaign.dispatched_time,
    allDay: false,
    id: campaign.id,
    isSent: campaign.is_sent,
    isCampaign: true,
    isFestival: false,
    priority: 3,
    channel: campaign.channel,
  }));
};

export const formatEmailAndSmsCampaigns = campaigns => {
  if (Object.keys(campaigns).length === 0) {
    return [];
  }

  return campaigns.map(campaign => ({
    title: campaign.title,
    start: campaign.scheduled_time
      ? new Date(campaign.scheduled_time * 1000).toISOString()
      : campaign.dispatched_time,
    end: campaign.scheduled_time
      ? new Date(campaign.scheduled_time * 1000).toISOString()
      : campaign.dispatched_time,
    allDay: false,
    id: campaign.id,
    emailCampaignId: campaign.campaign_id,
    isSent: campaign.is_sent,
    isCampaign: true,
    isFestival: false,
    priority: 4,
    channel: campaign.channel,
  }));
};

export const formatFestivals = festivals => {
  return festivals.map(festival => ({
    ...festival,
    start: festival.start_date,
    end: festival.end_date,
    allDay: true,
    isFestival: true,
    isCampaign: false,
    isSent: false,
    priority: 1,
  }));
};

// This function retrieves the user's timezone using the Intl API
export function getUserTimezone(): string {
  if (typeof Intl !== 'undefined') {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return 'UTC'; // Default to UTC if unable to determine the timezone
}

// This function takes a date and converts it to the user's local timezone
export function convertToUserTimezone(date: Date): Date {
  const userTimezone = getUserTimezone();
  return new Date(date.toLocaleString('en-US', { timeZone: userTimezone }));
}

// Utility function to check if a date has passed
export const isDatePassed = date => {
  return isBefore(startOfDay(new Date(date)), startOfDay(new Date()));
};

export const filterUniqueTitles = checklist => {
  const uniqueTitles = new Set();
  return checklist.reduce((results, item) => {
    const { checklistTitle, id, checklistId } = item;
    if (!uniqueTitles.has(checklistTitle)) {
      uniqueTitles.add(checklistTitle);
      results.push({ checklistTitle, id, checklistId });
    }
    return results;
  }, []);
};

/**
 * Processes a date selected from FullCalendar and returns a scheduled date.
 * If no date is provided, returns tomorrow at 10 AM.
 * If date is provided:
 * - For future dates: Sets the time to 10 AM
 * - For today: Adds 1 hour to current time
 * - For past dates: Returns tomorrow at 10 AM
 * - If adding 1 hour changes the day: Returns that day at 10 AM
 */
export const getScheduledCampaignDateAndTime = selectedDate => {
  const now = new Date();

  // If no date is selected, set for next day at 10 AM
  if (!selectedDate) {
    const scheduledDate = new Date(now);
    scheduledDate.setDate(scheduledDate.getDate() + 1);
    scheduledDate.setHours(10, 0, 0, 0);
    return scheduledDate;
  }

  const dateToProcess = new Date(selectedDate);
  const scheduledDate = new Date(dateToProcess);

  // Check if the selected date is in the future (different day)
  if (dateToProcess.toDateString() !== now.toDateString() && dateToProcess > now) {
    scheduledDate.setHours(10, 0, 0, 0);
    return scheduledDate;
  }

  // For today or past dates, keep the existing logic
  scheduledDate.setHours(
    scheduledDate.getHours() + 1,
    scheduledDate.getMinutes(),
  );

  // Check if the new time is on the next day
  if (scheduledDate.getDate() !== dateToProcess.getDate()) {
    scheduledDate.setDate(scheduledDate.getDate());
    scheduledDate.setHours(10, 0, 0, 0);
  }

  // If scheduled date is in the past, adjust accordingly
  if (scheduledDate < now) {
    // If it's today and past current time, set to next hour
    if (scheduledDate.toDateString() === now.toDateString()) {
      scheduledDate.setTime(now.getTime());
      scheduledDate.setHours(scheduledDate.getHours() + 1);
      // Keep the current minutes
      scheduledDate.setMinutes(now.getMinutes());

      // If adding an hour pushes to next day, set to tomorrow 10 AM
      if (scheduledDate.getDate() !== now.getDate()) {
        scheduledDate.setDate(scheduledDate.getDate());
        scheduledDate.setHours(10, 0, 0, 0);
      }
    } else {
      // If it's a past date, set to tomorrow 10 AM
      scheduledDate.setDate(now.getDate() + 1);
      scheduledDate.setHours(10, 0, 0, 0);
    }
  }

  return scheduledDate;
};
