import { EmailOnboardingStep } from 'src/modules/email-onboarding/models/constants';
import { ReservedElementNodeId } from 'src/modules/optins/components/FormWidget/lib/constants';
import { DeviceType } from 'src/modules/optins/models/types';
import { SMSOnboardingStep } from 'src/modules/sms/models/constants';

export enum ChannelType {
  'WEBPUSH' = 'webpush',
  'SMS' = 'sms',
  'EMAIL' = 'email',
}

export enum SubscriptionCycle {
  'MONTHLY' = 'monthly',
  'ANNUAL' = 'annual',
}

export enum Status {
  'ACTIVE' = 'active',
  'INACTIVE' = 'inactive',
  'DRAFT' = 'draft',
}

export enum FlowNodeType {
  'WAIT_TIME' = 'wait_time',
  'FORM_COLLECTION' = 'form_collection',
}

export enum OptinType {
  POPUP = 'popup',
  CUSTOM_WEBPUSH = 'custom_webpush',
  BROWSER_WEBPUSH = 'browser_webpush',
}

export enum FormWidgetType {
  STEP_1 = 'step_1',
  STEP_2 = 'step_2',
  TEASER = 'teaser',
}

export enum FormWidgetPosition {
  CENTERED = 'centered',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_CENTERED = 'bottom_centered',
  BOTTOM_RIGHT = 'bottom_right',
  TOP_LEFT = 'top_left',
  TOP_CENTERED = 'top_centered',
  TOP_RIGHT = 'top_right',
}

// general constants
export const ChannelDisplayName: Record<ChannelType, string> = {
  [ChannelType.EMAIL]: 'Email',
  [ChannelType.SMS]: 'SMS',
  [ChannelType.WEBPUSH]: 'Web Push',
};

export const DeviceTypeDisplayName: Record<DeviceType, string> = {
  all: 'All devices',
  desktop: 'Desktop',
  mobile: 'Mobile',
  mobile_ios: 'Mobile iOS',
  mobile_android: 'Mobile Android',
};

export const OptinTypeDisplayName: Record<OptinType, string> = {
  [OptinType.POPUP]: 'Popup',
  [OptinType.CUSTOM_WEBPUSH]: 'Custom Prompt',
  [OptinType.BROWSER_WEBPUSH]: 'Browser Prompt',
};

export const FormWidgetTypeDisplayName: Record<FormWidgetType, string> = {
  [FormWidgetType.STEP_1]: 'Step 1',
  [FormWidgetType.STEP_2]: 'Step 2',
  [FormWidgetType.TEASER]: 'Teaser',
};

export const StatusDisplayName: Record<Status, string> = {
  [Status.ACTIVE]: 'Active',
  [Status.INACTIVE]: 'Inactive',
  [Status.DRAFT]: 'Draft',
};

export const ReservedTextNodeCharacterLimits: Record<string, number> = {
  [ReservedElementNodeId.TITLE]: 50,
  [ReservedElementNodeId.SUBTITLE]: 100,
  [ReservedElementNodeId.TEASER]: 45,
};

export const OptinFlowPreviewTooltipContent = {
  [OptinType.BROWSER_WEBPUSH]: 'Single step optin',
  [OptinType.CUSTOM_WEBPUSH]: '2 step optin',
};

export const PositioningDisplayName: Record<FormWidgetPosition, string> = {
  [FormWidgetPosition.BOTTOM_LEFT]: 'Bottom Left',
  [FormWidgetPosition.BOTTOM_RIGHT]: 'Bottom Right',
  [FormWidgetPosition.BOTTOM_CENTERED]: 'Bottom Centered',
  [FormWidgetPosition.CENTERED]: 'Centered',
  [FormWidgetPosition.TOP_CENTERED]: 'Top Centered',
  [FormWidgetPosition.TOP_LEFT]: 'Top Left',
  [FormWidgetPosition.TOP_RIGHT]: 'Top Right',
};

export const WebPushMobilePositioningDisplayName: Record<string, string> = {
  [FormWidgetPosition.BOTTOM_CENTERED]: 'Bottom',
  [FormWidgetPosition.TOP_CENTERED]: 'Top',
};

export enum MerchantTask {
  DATA_SYNC_SHOPIFY_TO_BREVO = 'data_sync_shopify_to_brevo',
}

export const IntercomTriggerMessage = {
  WANT_HIGHER_EMAIL_CREDITS:
    'Hi PushOwl Support Team, I want to purchase more email credits. Could you please help me with it?',
  WANT_HIGHER_WEBPUSH_CREDITS:
    'Hi PushOwl Support Team, I want to purchase more webpush credits. Could you please help me with it?',
  WANT_HIGHER_SMS_CREDITS:
    'Hi PushOwl Support Team, I want to purchase more sms credits. Could you please help me with it?',
  FRAUD_DETECTION_CONTACT_SUPPORT:
    'Hi PushOwl Support Team, I was blocked from using the email feature due to unverified store details. Could you please help me verify my store and restore access?',
  [EmailOnboardingStep.ABOUT_1]:
    'Hi, PushOwl Email looks interesting. I want to know more about it.',
  [EmailOnboardingStep.ABOUT_2]:
    'Hi, PushOwl Email looks interesting. I want to know more about it.',
  [EmailOnboardingStep.SYNC_DATA]:
    'Hi, I have some questions on the data sync process.',
  [SMSOnboardingStep.ABOUT_SMS_1]:
    'Hi, Pushowl SMS looks Interesting. I want to know more about it.',
  [SMSOnboardingStep.SMS_STEP_1]:
    'Hi, I have some questions regarding the country selection for SMS.',
  [SMSOnboardingStep.SMS_STEP_2]:
    'Hi, I have some quesitons regarding the manage compliance for SMS.',
  [SMSOnboardingStep.SMS_STEP_3]:
    'Hi, I have some questions regarding the SMS pricing.',
  [SMSOnboardingStep.FRAUD_DETECTION_BLOCKED]:
    'Hi PushOwl Support Team, I was blocked from using the SMS feature due to unverified store details. Could you please help me verify my store and restore access?',
  [SMSOnboardingStep.FRAUD_DETECTION_PENDING]:
    'Hi PushOwl Support Team, my SMS status shows as "Pending".Could you please help me verify my store?',
  [SMSOnboardingStep.FRAUD_DETECTION_PHONE]:
    'Hi I have some questions regarding the phone verification.',
  [SMSOnboardingStep.FRAUD_DETECTION_OTP]:
    'Hi I have some questions regarding the OTP phone verification.',
  [SMSOnboardingStep.DATA_SYNC]:
    'Hi I have some questions regarding the Data Sync Step in SMS Onboarding.',
  SYNC_DATA_FAILED:
    'Hi, The data sync process has failed, can you help me out?',
  DEFAULT_EMAIL:
    'Hi, PushOwl Email looks interesting. I want to know more about it.',
  MERCHANT_IN_ANNUAL_PLAN:
    'Hi, I want to get started with PushOwl Email and would like to talk about a suitable plan that serves my needs.',
  PRICING_CHAT_WITH_US:
    'Hi, I need help with determining the best plan according to my needs.',
  DKIM_2K_RECORD: 'Hi, I need help with setting up a 2048-bit DKIM key',
  DEDICATED_IP:
    "Hi, I'd like to explore how a Dedicated IP can boost my email deliverability and sender reputation",
  DEFAULT_EMAIL_BILLING: 'Hi, I have some questions regarding my current plan',
  SMS_ACTIVATE:
    'Hi, PushOwl SMS looks interesting. I want to know more about it.',
  NEW_SMS_ACTIVATE:
    'Hi, I have completed the onboarding process for SMS, could you enable it for me now?',
  EMAIL_SMS_ACTIVATE:
    'Hi, PushOwl Email & SMS looks interesting. I want to know more about it.',
  ONBOARDING_TRIAL: 'Hi, I want to know more about the trial plans',
  AI_GPT_TEMPLATE:
    'Hi, I want to know more about the AI Email Template Generator',
  CUSTOM_OPTIN_EDITOR:
    'Hi, I want to know more about the Custom Optin Code Editor',
  TRIAL: 'Hi, I want to know more about the trial plans',
  SHORT_CODE:
    'Hi, I am at the Manage Compliance Step for SMS onboarding and I would like to register for shortcode.',
};

export const EmailHelpDocumentation = {
  [EmailOnboardingStep.ABOUT_1]: 'https://pushowl.com/email',
  [EmailOnboardingStep.ABOUT_2]: 'https://pushowl.com/email',
  [EmailOnboardingStep.SYNC_DATA]:
    'https://docs.pushowl.com/en/articles/9024515-sync-your-data-from-shopify',
  [EmailOnboardingStep.PRICING]: 'https://pushowl.com/email',
  HOME_PAGE: 'https://docs.pushowl.com',
};

export const CONTACT_ATTRIBUTE_BLACKLIST = [
  'STATE',
  'VERIFIED_EMAIL',
  'SHOPIFY_GID',
  'CURRENCY',
  'TAX_EXEMPT',
  'TOTAL_SPENT',
  'ORDER_COUNT',
  'MARKET_ID',
  'MARKET_HANDLE',
  'LOCALE',
  'DOUBLE_OPT-IN',
  'OPT_IN',
  'SMS',
  'EMAIL',
  'CUSTOMER_ID',
];

export const GPT_YOUTUBE_URL = 'https://www.youtube.com/watch?v=CR8tGQc1FSE';
export const GPT_VIDEO_AWS_URL =
  "https://gpt-template-assets.s3.amazonaws.com/PushOwl's+AI+Email+Campaign+Generator+(11).mp4";
