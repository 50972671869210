import { format, subSeconds } from 'date-fns';
import {
  dateRangeFilterOptions,
  DaysInSeconds,
} from 'src/components/HistoricalDaterangePicker/types';
import {
  AutomationNames,
  DaysOfWeek,
  getDefaultTimeZone,
  ReportSaverState,
  ReportsGranularity,
  ReportTypes,
} from 'src/components/ReportSaver/models';
import {
  AttributionAction,
  AttributionWindow,
  transformAttributionSettingsRequest as getAttributionWindow,
  transformAttributionSettingsResponse,
} from 'src/modules/settings/models';
import { get } from '../axios';

/**
 * Mapping between backend and frontend automation terms
 */
export const reportNameMapping = {
  total: 'total',
  post_subscription: 'welcomeNotifications',
  abandoned_cart: 'abandonedCart',
  browse_abandonment: 'browseAbandonment',
  back_in_stock: 'backInStock',
  price_drop: 'priceDrop',
  fulfillment_complete: 'shippingNotifications',
};

/**
 * Returns a relative or absolute time window
 *
 * @param value: number | {start: Date; end: Date}
 * @returns {type: "relative" | "absolute", data: {seconds: number} | {start_time: ISODate, end_time: ISODate}}
 */
export const getTimeWindow = (value: number | { start: Date; end: Date }) => {
  if (typeof value === 'object')
    return {
      type: 'absolute',
      data: {
        start_time: format(value.start, "uuuu-MM-dd'T'HH:mm:ss.SSSXXX"),
        end_time: format(value.end, "uuuu-MM-dd'T'HH:mm:ss.SSSXXX"),
      },
    };

  if (value !== DaysInSeconds.ALL_TIME)
    return {
      type: 'relative',
      data: {
        seconds: value,
      },
    };

  return {
    type: 'relative',
    data: {
      // We are hardcoding the start time to 2018-10-07 because we don't have data before that
      // We can't use store created_at date since we have merchant who have migrated from Bigc to Shopify
      // and the store created_at date will be wrong for them
      // We want to use string date instead of date object since it will be used in the key for caching the result in backend
      start_time: '2018-10-07',
    },
  };
};

interface Cron {
  time: Date;
  granularity: 'day' | 'week' | 'month';
  dayOfWeek: string | number; // 0-6
  dayOfMonth: string | number; // 1-31
}

/**
 * Converts to cron string
 *
 * @param Cron
 * @returns cron string
 */
export const getCron = ({ time, granularity, dayOfWeek, dayOfMonth }: Cron) => {
  const cronTime = format(time, 'm H');

  let cronDayOfMonth = '*';
  let cronMonth = '*';
  let cronDayOfWeek = '*';

  if (granularity === 'day') cronDayOfWeek = '0-6';
  else if (granularity === 'week') cronDayOfWeek = String(dayOfWeek);
  else {
    cronMonth = '1-12';
    cronDayOfMonth = String(dayOfMonth);
  }

  return `${cronTime} ${cronDayOfMonth} ${cronMonth} ${cronDayOfWeek}`;
};

interface ReportsRequest {
  method: Function;
  url: string;
  filters: {
    segmentIds: string[];
    campaignTypes: string[];
    attributedCampaignId: number;
    growthGranularity: ReportsGranularity;
    automationName: AutomationNames;
  };
  reportState: ReportSaverState;
}

/**
 * Makes the request to save/ update a report.
 * Converts frontend store shape to API shape.
 *
 * The filters object contains props that are
 * required for saving reports but do not exist
 * in the Report Saver model.
 *
 * Instead, they're stored and sourced from other
 * models.
 *
 * We store these props in the Report Saver model
 * when we fetch the created report to display on
 * the Reports page, so they have corresponding
 * entries in the reportState prop.
 *
 * Only attributedCampaignId does not because
 * campaign order data reports are not shown on
 * the Reports page.
 *
 * @param ReportsRequest
 * @returns {error: boolean}
 */
export const makeReportsRequest = async ({
  method,
  url,
  filters: {
    segmentIds: saveSegmentIds,
    campaignTypes: saveCampaignTypes,
    attributedCampaignId = 0,
    growthGranularity: saveGrowthGranularity,
    automationName: saveAutomationName,
  },
  reportState: {
    timeFilter,
    state,
    reportName,
    reportType,
    granularity,
    isRecurringSelected,
    recipientList,
    attributionSettings,
    dayOfMonth,
    dayOfWeek,
    deliveryTime,
    timeZone,
    subscriberGrowthGranularity: updateGrowthGranularity,
    campaignTypes: updateCampaignTypes,
    segmentIds: updateSegmentIds,
    automationName: updateAutomationName,
  },
}: ReportsRequest) => {
  const growthGranularity = saveGrowthGranularity || updateGrowthGranularity;
  const segmentIds = saveSegmentIds || updateSegmentIds;
  const campaignTypes = saveCampaignTypes || updateCampaignTypes;
  const automationName = saveAutomationName || updateAutomationName;

  const timeWindow = getTimeWindow(timeFilter.value);

  const email = recipientList.split(',').map(mail => mail.trim());

  const crontabStr = getCron({
    time: deliveryTime,
    granularity,
    dayOfWeek: String(dayOfWeek),
    dayOfMonth,
  });

  const schedule = isRecurringSelected
    ? {
        timezone: {
          name: timeZone,
        },
        crontab_str: crontabStr,
      }
    : {};

  const windows = attributionSettings.map(({ action, duration, window }) =>
    getAttributionWindow({
      attributionAction: action,
      attributionDuration: duration,
      attributionWindow: window,
    }),
  );

  const result = await Promise.all(
    windows.map(window =>
      method(url, {
        name: reportType,
        nickname: reportName,

        params:
          reportType === ReportTypes.CUSTOM
            ? {}
            : {
                ...(reportType === ReportTypes.CAMPAIGN_ORDER_DATA
                  ? {}
                  : { time_window: timeWindow }),

                ...(reportType === ReportTypes.SUBSCRIBER_GROWTH
                  ? {}
                  : { attribution_window: window }),

                ...(reportType === ReportTypes.SUBSCRIBER_GROWTH &&
                growthGranularity
                  ? { granularity: growthGranularity }
                  : {}),

                ...(reportType === ReportTypes.CAMPAIGN_ORDER_DATA
                  ? { campaign_ids: [attributedCampaignId] }
                  : {}),

                ...(reportType === ReportTypes.CAMPAIGN_PERF && segmentIds
                  ? {
                      segment_ids: segmentIds.map(id => +id),
                      campaign_types: campaignTypes,
                      campaign_state: 'sent',
                    }
                  : {}),

                ...([
                  ReportTypes.AUTOMATION_REMINDER_PERF,
                  ReportTypes.AUTOMATION_ORDER_DATA,
                ].includes(reportType as ReportTypes)
                  ? { automation_name: automationName }
                  : {}),
              },

        state,
        schedule,
        recipients: {
          email,
        },
      }),
    ),
  );

  if (result.find(obj => obj.error)) return { error: true };

  return { error: false };
};

/**
 * Creates the request for ACR/ BA performance metrics, reminder stats, and order data and returns
 * it in a structure suitable for the redux stores abandonedCartAutomation/ browseAbandonmentAutomation
 *
 * @param automationName {string}
 * @param payload {{value}}
 * @param attributionSettings {{attributionAction, attributionDuration, attributionWindow}}
 * @returns {reports, orderData, error}
 */
export const makeAutomationReportRequest = async (
  automationName,
  { value },
  attributionSettings,
) => {
  const attribution_window = getAttributionWindow(attributionSettings);

  const time_window = getTimeWindow(value);

  const reportData = btoa(
    JSON.stringify([
      {
        name: ReportTypes.AUTOMATION_REMINDER_PERF,
        params: {
          automation_name: automationName,
          time_window,
          attribution_window,
        },
      },
      {
        name: ReportTypes.AUTOMATION_ORDER_DATA,
        params: {
          automation_name: automationName,
          time_window,
          attribution_window,
        },
        page: 1,
        records_per_page: 10,
      },
    ]),
  );

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data.find(a => a.status === 'failed'))
    return { reports: null, error: error || true, orderData: null };

  const reports = data[0].report.filter(r =>
    ['grand_total', 'total'].includes(r.title),
  );

  const reportsObject: AnyObject = { reminders: [] };

  reports.forEach(r => {
    const stats = {
      impressions: +r.impressions,
      clicks: +r.clicks,
      revenue: +r.revenue,
      cartsRecovered: +r.orders,
      ctr: +r.click_rate,
    };

    if (r.title === 'grand_total') reportsObject.total = stats;
    else
      reportsObject.reminders.push({
        ...stats,
        sequenceNumber: r.reminder_seq,
      });
  });

  const { report } = data[1];
  const { count } = report.find(r => r.order_id === null);

  const orderData = report
    .filter(r => r.order_id !== null)
    .map(
      ({
        order_id,
        total_price,
        first_name,
        last_name,
        browser,
        device,
        currency,
        order_name,
      }) => ({
        orderId: order_id,
        orderName: order_name,
        totalPrice: total_price,
        name: `${first_name || ''} ${last_name || ''}`.trim(),
        browser,
        currency,
        device,
      }),
    );

  return {
    reports: reportsObject,
    orderData: {
      totalCount: count,
      hasMore: count > 10,
      recordsPerPage: 20,
      orders: orderData,
    },
    error: null,
  };
};

/**
 * Creates the request for ACR/ BA order data and returns it in a structure
 * suitable for the redux stores abandonedCartAutomation/ browseAbandonmentAutomation
 *
 * @param automationName {string}
 * @param payload {timeFilter: {value}, recordsPerPage: number}
 * @param attributionSettings {{attributionAction, attributionDuration, attributionWindow}}
 * @returns {orderData, error}
 */
export const makeAutomationOrderRequest = async (
  automationName,
  { timeFilter: { value }, recordsPerPage },
  attributionSettings,
) => {
  const attribution_window = getAttributionWindow(attributionSettings);

  const time_window = getTimeWindow(value);

  const reportData = btoa(
    JSON.stringify([
      {
        name: ReportTypes.AUTOMATION_ORDER_DATA,
        params: {
          automation_name: automationName,
          time_window,
          attribution_window,
        },
        page: 1,
        records_per_page: recordsPerPage,
      },
    ]),
  );

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data.find(a => a.status === 'failed'))
    return { error: error || true, orderData: null };

  const { report } = data[0];
  const { count } = report.find(r => r.order_id === null);

  const orderData = report
    .filter(r => r.order_id !== null)
    .map(
      ({
        order_id,
        order_name,
        total_price,
        first_name,
        last_name,
        browser,
        device,
        currency,
      }) => ({
        orderId: order_id,
        orderName: order_name,
        totalPrice: total_price,
        name: `${first_name || ''} ${last_name || ''}`.trim(),
        browser,
        currency,
        device,
      }),
    );

  return {
    orderData: {
      totalCount: count,
      hasMore: count > recordsPerPage,
      recordsPerPage: recordsPerPage + 10,
      orders: orderData,
    },
    error: null,
  };
};

/**
 * Transforms the total automation stats report response into the frontend structure
 * used in redux
 *
 * @param data {API object}
 * @returns report object in the shape of the AutomationStats model
 */
export const transformTotalAutomationStats = data => {
  const automationsStats = {};

  data[0].report.forEach(r => {
    const report = { ...r };

    const name = reportNameMapping[report.automation];
    delete report.automation;

    Object.entries(report).forEach(([key, value]) => {
      report[key] = +value;
    });

    automationsStats[name] = {
      ...report,
    };
  });

  return { data: automationsStats, error: null };
};

/**
 * Transforms the API response object for all reports into the frontend structure
 * used in redux
 *
 * @param report {API object}
 * @returns report object in the shape of the ReportSaver model
 */
/* eslint-disable no-nested-ternary */
export const transformAllReportsResponse = report => {
  const {
    id,
    created_at,
    name,
    nickname,
    state: reportState,
    recipients: { email },
    schedule,
    params: {
      attribution_window: attributionSettings,
      time_window: timeWindow,
      granularity: subscriberGrowthGranularity,
      campaign_types: campaignTypes,
      segment_ids: segmentIds,
      automation_name: automationName,
    },
  } = report;

  const isRecurring = Object.keys(schedule).length > 0;

  const state = {
    id,
    createdAt: new Date(created_at),
    state: reportState,
    reportType: name,
    reportName: nickname,
    granularity: ReportsGranularity.DAILY,
    // There is side effect going on here causing some issues with timefilter. This needs to be changed to make sure state object is not mutated
    timeFilter: {
      value: DaysInSeconds.LAST_30_DAYS,
      label: 'Last 30 days',
      type: 'fixed',
    },
    isRecurringSelected: isRecurring,
    recipientList: email.join(', '),
    attributionSettings: [
      {
        action: AttributionAction.CLICK,
        duration: 24,
        window: AttributionWindow.HOURS,
      },
    ],
    dayOfMonth: 1,
    dayOfWeek: DaysOfWeek.MON,
    deliveryTime: new Date(),
    timeZone: getDefaultTimeZone(),
    isSaveable: true,
    subscriberGrowthGranularity,
    campaignTypes,
    segmentIds,
    automationName,
  };

  if (timeWindow) {
    const { type, data } = timeWindow;

    if (type === 'relative') {
      if (data.seconds) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.timeFilter = dateRangeFilterOptions.find(
          option => option.value === data.seconds,
        );
        // All Time time window will be of type relative with start_time instead of seconds in data
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.timeFilter = dateRangeFilterOptions.find(
          option => option.value === DaysInSeconds.ALL_TIME,
        );
      }
    } else {
      delete state.timeFilter.label;
      state.timeFilter.type = 'range';
      // eslint-disable-next-line
      // @ts-ignore
      state.timeFilter.value = {
        start: new Date(data.start_time),
        end: new Date(data.end_time),
      };
    }
  }

  if (isRecurring) {
    const {
      timezone: { name: timeZone },
      crontab_str,
    } = schedule;
    const [mins, hours, dayOfMonth, _, dayOfWeek] = crontab_str.split(' ');
    const granularity =
      dayOfWeek === '*'
        ? ReportsGranularity.MONTHLY
        : dayOfWeek.includes('-')
        ? ReportsGranularity.DAILY
        : ReportsGranularity.WEEKLY;

    state.deliveryTime = new Date(new Date().setHours(hours, mins));
    state.dayOfMonth = dayOfMonth === '*' ? 1 : +dayOfMonth;
    state.dayOfWeek =
      dayOfWeek === '*' || dayOfWeek.includes('-') ? DaysOfWeek.MON : dayOfWeek;
    state.granularity = granularity;
    state.timeZone = timeZone;
  }

  if (attributionSettings) {
    const { attributionAction, attributionDuration, attributionWindow } =
      transformAttributionSettingsResponse(attributionSettings);

    const settings = state.attributionSettings[0];

    settings.action = attributionAction;
    settings.duration = attributionDuration;
    settings.window = attributionWindow;
  }

  return state;
};

/**
 * Creates and makes the request for campaign reports
 * Transforms the response into the frontend structure
 *
 * @param campaignState {"sent" | "scheduled" | "paused"}
 * @param payload {various campaigns filters}
 * @param attributionSettings {{attributionAction, attributionDuration, attributionWindow}}
 * @returns object with total stats for all campaigns of type campaignState along with individual campaign metadata
 */
export const makeCampaignsReportsRequest = async (
  campaignState,
  { timeFilter: { value }, segmentIds, campaignTypes, page },
  attributionSettings,
) => {
  const attribution_window = getAttributionWindow(attributionSettings);

  const time_window = getTimeWindow(value);

  const reportData = window.btoa(
    JSON.stringify([
      {
        name: ReportTypes.CAMPAIGN_PERF,
        params: {
          time_window,
          attribution_window,
          segment_ids: segmentIds.map(id => +id),
          campaign_types: campaignTypes,
          campaign_state: campaignState,
        },
        page,
        records_per_page: 10,
      },
    ]),
  );

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data[0].status === 'failed')
    return { reports: null, error: error || true };

  const reports = data[0].report;

  const campaigns = reports.map(r => {
    const {
      campaign_id,
      title,
      message,
      redirect_url,
      discount_code,
      icon,
      image,
      mobile_image,
      scheduled_time,
      time_sent,
      created_at,
      images,
      end_campaign_time,
      ttl,
      actions,
      impressions,
      click_rate,
      clicks,
      segment_id,
      segment_name,
      type,
      orders,
      conversion,
      average_order_value,
      revenue,
    } = r;

    return {
      id: campaign_id,
      title,
      description: message,
      redirect_url,
      discount_code,
      icon,
      image,
      mobile_image,
      scheduled_time,
      dispatched_at: time_sent,
      created_at,
      images,
      end_campaign_time,
      ttl,
      actions,
      delivered: impressions,
      ctr: click_rate,
      clicks,
      segment_id,
      segment_name,
      type,
      orders,
      conversion,
      averageOrderValue: average_order_value,
      attributed_revenue: revenue,
    };
  });

  return { reports: campaigns };
};
