export enum OnboardingStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum EmailOnboardingStep {
  ABOUT_1,
  ABOUT_2,
  FRAUD_DETECTION,
  VERIFY_PHONE_NUMBER,
  FRAUD_DETECTION_PENDING,
  FRAUD_DETECTION_BLOCKED,
  SYNC_DATA,
  PRICING,
}

export const setupEmailStep = {
  [EmailOnboardingStep.SYNC_DATA]: 1,
  [EmailOnboardingStep.PRICING]: 3,
};

/**
 * do not show modal if subaccount created after june 19, 2024
 */
export const SHOW_MFE_INTRO_MODAL_AFTER_DATE = '2024-06-19T00:00:00.000Z';

export enum OnboardingSource {
  email = 'email_onboarding',
  sms = 'sms_onboarding',
}
