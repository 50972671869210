export enum OnboardingStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum SMSOnboardingStep {
  ABOUT_SMS_1 = 'about',
  SMS_STEP_1 = 'target-audience',
  SMS_STEP_2 = 'manage-compliance',
  SMS_STEP_3 = 'pricing',
  FRAUD_DETECTION = 'verify-store',
  FRAUD_DETECTION_PHONE = 'verify-phone',
  FRAUD_DETECTION_OTP = 'verify-otp',
  DATA_SYNC = 'data-sync',
  FRAUD_DETECTION_PENDING = 'verify-pending',
  FRAUD_DETECTION_BLOCKED = 'verify-blocked',
}
