import { CHECKLIST_TASK_STATUS } from 'src/modules/content-calendar/models/constants';
import { del, get, post } from './axios';

const CALENDAR_CAMPAIGNS_ENDPOINT = '/dashboard/calendar/campaigns/';

const CALENDAR_FESTIVALS_ENDPOINT = '/dashboard/calendar/festivals/';

const CALENDAR_EMAIL_CAMPAIGNS_ENDPOINT = CALENDAR_CAMPAIGNS_ENDPOINT;

const CALENDAR_WEBPUSH_CAMPAIGNS_ENDPOINT = `${CALENDAR_CAMPAIGNS_ENDPOINT}webpush/`;

const CALENDAR_CHECKLISTS_ENDPOINT = `/dashboard/checklists/tasks/`;

export const deleteCalendarEmailCampaignAPI = (id: number) => {
  return del(`${CALENDAR_CAMPAIGNS_ENDPOINT}email/${id}`);
};

const CALENDAR_CAMPAIGN_CONTENT_GENERATION_ENDPOINT = (
  id: string,
  channel: string = 'webpush',
) => `/dashboard/calendar/festivals/${id}/campaign-content/${channel}`;

export const fetchCalendarFestivalsAPI = () => get(CALENDAR_FESTIVALS_ENDPOINT);

export const fetchCalendarEmailCampaignsAPI = () =>
  get(CALENDAR_EMAIL_CAMPAIGNS_ENDPOINT);

export const fetchCalendarWebpushCampaignsAPI = () =>
  get(CALENDAR_WEBPUSH_CAMPAIGNS_ENDPOINT);

export const fetchCalendarCampaignContentAPI = (id: string, channel?: string) =>
  get(CALENDAR_CAMPAIGN_CONTENT_GENERATION_ENDPOINT(id, channel));

// keeping these dates static for now as we do not need to fetch the checklist items for any other year except the current year for now. This chan be changed in future if needed.
export const fetchCalendarChecklistAPI = (
  startDate = '2024-01-01',
  endDate = '2024-12-31',
) =>
  get(
    `${CALENDAR_CHECKLISTS_ENDPOINT}?start_date="${startDate}"&&end_date="${endDate}"`,
  );

export const postCalendarChecklistTaskAPI = ({
  taskId,
  status,
}: {
  taskId: number;
  status: CHECKLIST_TASK_STATUS.TODO | CHECKLIST_TASK_STATUS.DONE;
}) => {
  return post(CALENDAR_CHECKLISTS_ENDPOINT, {
    task_id: taskId,
    status,
  });
};
