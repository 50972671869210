import { createModel } from '@rematch/core';
import { fetchSubAccountAPI } from 'src/lib/api/brevo';
import { RootModel } from 'src/store/models';

type DataSyncStatus = 'not_started' | 'failed' | 'completed' | 'in_progress';

type SubAccountModelState = {
  subAccountId: number;
  dataSyncStatus: DataSyncStatus;
};

const initialState: SubAccountModelState = {
  subAccountId: undefined,
  dataSyncStatus: 'not_started',
};

const subAccount = createModel<RootModel>()({
  state: initialState,

  effects: dispatch => ({
    async getSubAccount() {
      const { data, error } = await fetchSubAccountAPI();

      if (data && !error) {
        dispatch.subAccount.setState({
          subAccountId: data.sub_account_id,
          dataSyncStatus: data.data_sync_status,
        });
      }
    },
  }),

  reducers: {
    setState(state, payload: Partial<SubAccountModelState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default subAccount;
