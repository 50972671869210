import { createModel } from '@rematch/core';
import _set from 'lodash.set';
import { script, thankYouScript } from 'src/constants/codeEditor';
import { FlowNodeType, FormWidgetType, OptinType } from 'src/lib/constants';
import { isPopupPromptFormWidget } from 'src/lib/utils';
import { OptinsData } from 'src/modules/optins/models';
import { CustomAttribute, FormWidget } from 'src/modules/optins/models/types';
import {
  applyChangesToNodeTree,
  createCustomFieldTreeNode,
  depthFirstTraversal,
  getDirectParentNode,
  getTreeNodeById,
} from 'src/modules/optins/util/treeUtils';
import { RootModel } from 'src/store/models';
import {
  ReservedContainerNodeId,
  ReservedElementNodeId,
  ROOT_NODE_ID,
} from '../../FormWidget/lib/constants';
import {
  CodeOrPreview,
  ImagePosition,
  TreeNode,
  TreeNodeByType,
  TreeNodeType,
} from '../../FormWidget/lib/types';
import { getTextColorProps, isHeadingText } from '../../FormWidget/lib/utils';
import { FormWidgetStep } from '../FormWidgetEditorAddon/AIEditor/models';

interface FormWidgetEditorWorkingState {
  workingFormWidget: FormWidget;
  selectedFormWidgetTreeNodeId: string;
  isChanged: boolean;
  isSettingsAddonOpen: boolean;
  isDesignEditorOpen: boolean;
  isSelectedChannelMapped: {
    email: boolean;
    sms: boolean;
  };
  isSmallScreen: boolean;
  isAIModalOpen: boolean;
  isApplyingStylesToAllForms: boolean;
  codeOrPreviewTab: CodeOrPreview;
}

const initialState: () => FormWidgetEditorWorkingState = () => ({
  workingFormWidget: null,
  selectedFormWidgetTreeNodeId: null,
  isChanged: false,
  isSettingsAddonOpen: false,
  isDesignEditorOpen: false,
  isSelectedChannelMapped: {
    email: false,
    sms: false,
  },
  isSmallScreen: false,
  isAIModalOpen: false,
  isApplyingStylesToAllForms: false,
  codeOrPreviewTab: 'code',
});

const formWidgetEditor = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    setWorkingFormWidget(
      payload: {
        formWidget: FormWidget;
        defaultNodeToSelect?: string;
        designEditorOpen?: boolean;
        setEmptySelectedNode?: boolean;
      },
      rootState,
    ) {
      this.storeConfig({
        workingFormWidget: payload.formWidget,
        isSettingsAddonOpen: false,
        isDesignEditorOpen: payload.designEditorOpen || false,
        selectedFormWidgetTreeNodeId: payload.setEmptySelectedNode
          ? ''
          : payload.defaultNodeToSelect ||
            rootState.formWidgetEditor.selectedFormWidgetTreeNodeId,
      });
    },

    setIsAIModalOpen(payload: { state: boolean }) {
      this.storeConfig({
        isAIModalOpen: payload.state,
      });
    },

    setCodeOrPreviewTab(payload: { tab: CodeOrPreview }) {
      this.storeConfig({
        codeOrPreviewTab: payload.tab,
      });
    },

    setSelectedWidgetTreeNode(payload: { id: string }) {
      this.storeConfig({
        selectedFormWidgetTreeNodeId: payload.id,
        isSettingsAddonOpen: false,
        isDesignEditorOpen: false,
      });
    },

    /**
     * @see {@link applyChangesToNodeTree}
     */
    setNodeContent(
      payload: {
        nodeId: string;
        updateWith: (node: TreeNode) => void;
        formWidget?: FormWidget;
      },
      rootState,
    ): FormWidget {
      const { workingFormWidget } = rootState.formWidgetEditor;
      const formWidget = payload.formWidget || workingFormWidget;

      if (
        formWidget &&
        formWidget.config &&
        isPopupPromptFormWidget(formWidget)
      ) {
        formWidget.config = applyChangesToNodeTree(
          payload.nodeId,
          payload.updateWith,
          formWidget.config,
        );

        if (payload?.formWidget) {
          if (payload?.formWidget?.id === workingFormWidget?.id) {
            this.storeConfig({
              workingFormWidget: formWidget,
              isChanged: true,
            });
          }
        } else {
          this.storeConfig({
            workingFormWidget: formWidget,
            isChanged: true,
          });
        }
      }

      return formWidget;
    },

    setTextColorByTheme(
      payload: {
        color: string;
        category?: 'heading' | 'body';
        formWidget?: FormWidget;
      },
      rootState,
    ): FormWidget {
      const { workingFormWidget } = rootState.formWidgetEditor;
      const formWidget = payload.formWidget || workingFormWidget;

      if (
        formWidget &&
        formWidget.config &&
        isPopupPromptFormWidget(formWidget)
      ) {
        formWidget.config = depthFirstTraversal(node => {
          switch (node.type) {
            case TreeNodeType.TEXT: {
              // eslint-disable-next-line dot-notation
              const isHeading = isHeadingText(node.attr?.['size'] || 'md');
              const shouldUpdateGlobalColor =
                (payload.category === 'heading' && isHeading) ||
                (payload.category === 'body' && !isHeading);

              if (shouldUpdateGlobalColor) {
                const textColor = getTextColorProps(payload.color);
                node.attr = {
                  ...(node.attr || {}),
                  color: textColor,
                };
              }
              break;
            }
            default:
              break;
          }
        }, formWidget.config);

        if (payload?.formWidget) {
          if (payload?.formWidget?.id === workingFormWidget?.id) {
            this.storeConfig({
              workingFormWidget: formWidget,
              isChanged: true,
            });
          }
        } else {
          this.storeConfig({
            workingFormWidget: formWidget,
            isChanged: true,
          });
        }
      }

      return formWidget;
    },

    /**
     * copies over global styles to all forms and saves them
     */
    async applyStylesToAllForms(_, rootState) {
      const {
        optin: { current: optin },
        selectedOptinNodeId,
      } = rootState.optins;

      const { workingFormWidget } = rootState.formWidgetEditor;

      const selectedOptin = optin.flow_nodes.find(
        optin => optin.id === selectedOptinNodeId,
      );

      if (
        selectedOptin &&
        selectedOptin.type === FlowNodeType.FORM_COLLECTION
      ) {
        const { forms } = selectedOptin;

        this.storeConfig({
          isApplyingStylesToAllForms: true,
        });

        await Promise.all(
          forms.map(async form => {
            if (
              isPopupPromptFormWidget(form) &&
              isPopupPromptFormWidget(workingFormWidget) &&
              workingFormWidget.config.type === TreeNodeType.BOX
            ) {
              const titleColor = getTreeNodeById(
                workingFormWidget.config,
                ReservedElementNodeId.TITLE,
              );
              const subtitleColor = getTreeNodeById(
                workingFormWidget.config,
                ReservedElementNodeId.SUBTITLE,
              );

              const currentPadding = workingFormWidget.config.attr.pad;
              const currentBg = workingFormWidget.config.attr.bgColor;
              const currentHeadColor =
                titleColor?.type === TreeNodeType.TEXT
                  ? titleColor?.attr?.color
                  : '#000000';
              const currentBodyColor =
                subtitleColor?.type === TreeNodeType.TEXT
                  ? subtitleColor?.attr?.color
                  : '#000000';
              const currentBorderCol = workingFormWidget.config.attr.borderCol;
              const currentBorderWidth =
                workingFormWidget.config.attr.borderWidth;
              const currentRadius = workingFormWidget.config.attr.radius;
              const currentWidth = workingFormWidget.config.attr.theme?.width;
              const currentHeight = workingFormWidget.config.attr.theme?.height;
              const currentFixedHeight =
                workingFormWidget.config.attr.theme?.fixedHeight;

              const isTeaser = form.step_type === FormWidgetType.TEASER;

              let updatedFormWidget = dispatch.formWidgetEditor.setNodeContent({
                nodeId: ROOT_NODE_ID,
                updateWith: node => {
                  if (node.type === TreeNodeType.BOX) {
                    node.attr = {
                      ...(node.attr || {}),
                      ...(!isTeaser ? { pad: currentPadding } : {}),
                      bgColor: currentBg,
                      borderCol: currentBorderCol,
                      borderWidth: currentBorderWidth,
                      radius: currentRadius,
                      theme: {
                        ...(node.attr.theme || {}),
                        headCol: currentHeadColor,
                        bodyCol: currentBodyColor,
                        ...(!isTeaser
                          ? {
                              width: currentWidth,
                              height: currentHeight,
                              fixedHeight: currentFixedHeight,
                            }
                          : {}),
                      },
                    };
                  }
                },
                formWidget: form,
              });
              updatedFormWidget = dispatch.formWidgetEditor.setTextColorByTheme(
                {
                  color: currentHeadColor,
                  category: 'heading',
                  formWidget: updatedFormWidget,
                },
              );
              updatedFormWidget = dispatch.formWidgetEditor.setTextColorByTheme(
                {
                  color: currentBodyColor,
                  category: 'body',
                  formWidget: updatedFormWidget,
                },
              );
              await dispatch.formWidgetEditor.saveFormWidget({
                optinNodeId: selectedOptinNodeId,
                formWidgetId: form.id,
                workingTree: updatedFormWidget.config,
              });
            }
          }),
        );

        this.storeConfig({
          isApplyingStylesToAllForms: false,
        });
      }
    },

    async setActivePromptWidget(payload: { activeType: OptinType }, rootState) {
      const activeConfig = {
        [OptinType.BROWSER_WEBPUSH]: 'oneStep',
        [OptinType.CUSTOM_WEBPUSH]: 'twoStep',
      }[payload.activeType];

      const { workingFormWidget } = rootState.formWidgetEditor;

      _set(workingFormWidget, `config.active`, activeConfig);

      this.storeConfig({
        workingFormWidget,
      });

      // update the form widget itself first
      await dispatch.formWidgetEditor.saveFormWidget({ silent: true });
      // update the node type then
      await dispatch.optins.saveOptinFlowNodeOptions({
        optin_type: payload.activeType,
      });

      dispatch.saveToast.showDone('Prompt type updated');
    },

    setPromptWidgetProp(
      payload: { path: string; value: any; isTextContent?: boolean },
      rootState,
    ) {
      const { workingFormWidget } = rootState.formWidgetEditor;

      _set(
        workingFormWidget,
        payload.isTextContent
          ? `config.twoStep.${payload.path}.default`
          : `config.twoStep.${payload.path}`,
        payload.value,
      );

      this.storeConfig({
        workingFormWidget,
        isChanged: true,
      });
    },

    async saveFormWidget(
      payload: {
        silent?: boolean;
        workingTree?: TreeNode | OptinsData;
        optinNodeId?: number;
        formWidgetId?: number;
        disableThumbnailRefresh?: boolean;
      },
      rootState,
    ) {
      await dispatch.optins.saveFormWidgetTree({
        silent: Boolean(payload?.silent),
        disableThumbnailRefresh: Boolean(payload?.disableThumbnailRefresh),
        workingTree:
          payload?.workingTree ||
          rootState.formWidgetEditor.workingFormWidget.config,
        optinNodeId: payload?.optinNodeId,
        formWidgetId: payload?.formWidgetId,
      });
      this.storeConfig({
        isChanged: false,
      });
    },

    discardFormWidgetChanges(_, rootState) {
      if (!rootState.optins.isFormWidgetSaving) {
        dispatch.optins.syncCurrentTreeOntoWorkingTree();
        this.storeConfig({
          isChanged: false,
        });
      }
    },

    toggleSettingsAddon(payload: { state: boolean }, rootState) {
      this.storeConfig({
        isSettingsAddonOpen: payload.state,
        isDesignEditorOpen: false,
        selectedFormWidgetTreeNodeId: payload.state
          ? null
          : rootState.formWidgetEditor.selectedFormWidgetTreeNodeId,
      });
    },

    toggleDesignEditor(payload: { state: boolean }, rootState) {
      this.storeConfig({
        isDesignEditorOpen: payload.state,
        isSettingsAddonOpen: false,
        selectedFormWidgetTreeNodeId: payload.state
          ? null
          : rootState.formWidgetEditor.selectedFormWidgetTreeNodeId,
      });
    },

    changeImagePositioning(payload: { position: ImagePosition }, rootState) {
      const { workingFormWidget } = rootState.formWidgetEditor;

      const imageBoxNodeAttrKeys: (keyof TreeNodeByType<TreeNodeType.BOX>['attr'])[] =
        ['bgDims', 'bgUrl'];

      if (
        workingFormWidget &&
        workingFormWidget.config &&
        isPopupPromptFormWidget(workingFormWidget)
      ) {
        const oldImageNode = getTreeNodeById(
          workingFormWidget.config,
          ReservedContainerNodeId.IMAGE,
        );

        if (oldImageNode) {
          // reset the Box(image) node
          const imageNode = { ...oldImageNode };
          imageNode.attr = {
            // eslint-disable-next-line dot-notation
            bgUrl: oldImageNode.attr['bgUrl'],
            ...oldImageNode.attr,
          };

          const contentNode = getTreeNodeById(
            workingFormWidget.config,
            'container_1', // hardcoded for now
          );

          if (payload.position !== 'background') {
            // remove the imageBoxNodeAttrKeys from the root node
            workingFormWidget.config = applyChangesToNodeTree(
              ROOT_NODE_ID,
              node => {
                if (node.type === TreeNodeType.BOX) {
                  node.attr = Object.keys(node.attr).reduce(
                    (acc, key: (typeof imageBoxNodeAttrKeys)[number]) => {
                      if (!imageBoxNodeAttrKeys.includes(key)) {
                        acc[key] = node.attr[key];
                      }
                      return acc;
                    },
                    {},
                  );

                  imageNode.attr.hidden = false;
                  node.children = [{ ...imageNode }, { ...contentNode }];
                }
              },
              workingFormWidget.config,
            );
          }

          switch (payload.position) {
            case 'left': {
              workingFormWidget.config = applyChangesToNodeTree(
                ROOT_NODE_ID,
                node => {
                  if (node.type === TreeNodeType.BOX) {
                    node.attr.dir = 'LR';
                    node.children = [{ ...imageNode }, { ...contentNode }];
                  }
                },
                workingFormWidget.config,
              );
              break;
            }
            case 'right': {
              workingFormWidget.config = applyChangesToNodeTree(
                ROOT_NODE_ID,
                node => {
                  if (node.type === TreeNodeType.BOX) {
                    node.attr.dir = 'LR';
                    node.children = [{ ...contentNode }, { ...imageNode }];
                  }
                },
                workingFormWidget.config,
              );
              break;
            }
            case 'top': {
              workingFormWidget.config = applyChangesToNodeTree(
                ROOT_NODE_ID,
                node => {
                  if (node.type === TreeNodeType.BOX) {
                    node.attr.dir = 'TB';
                    // eslint-disable-next-line dot-notation
                    imageNode.attr['fill'] = '40%';
                    node.children = [{ ...imageNode }, { ...contentNode }];
                  }
                },
                workingFormWidget.config,
              );
              break;
            }
            case 'background': {
              workingFormWidget.config = applyChangesToNodeTree(
                ROOT_NODE_ID,
                node => {
                  node.attr = {
                    ...(node.attr || {}),
                    ...imageBoxNodeAttrKeys.reduce((acc, key) => {
                      acc[key] = imageNode.attr[key];
                      return acc;
                    }, {}),
                  };

                  imageNode.attr.hidden = true;
                  node.children = [{ ...imageNode }, { ...contentNode }];
                },
                workingFormWidget.config,
              );
              break;
            }
            default:
              break;
          }
        }

        this.storeConfig({
          workingFormWidget,
          isChanged: true,
        });
      }
    },

    addCustomField(payload: { attribute: CustomAttribute }, rootState) {
      const { workingFormWidget, selectedFormWidgetTreeNodeId } =
        rootState.formWidgetEditor;

      const customFieldNode = createCustomFieldTreeNode(payload.attribute);

      if (customFieldNode && isPopupPromptFormWidget(workingFormWidget)) {
        workingFormWidget.config = applyChangesToNodeTree(
          selectedFormWidgetTreeNodeId,
          node => {
            if (node.type === TreeNodeType.BOX) {
              const lastInputNodeIdx = node.children.findIndex(
                child => child.type === TreeNodeType.INPUT || TreeNodeType.DATE,
              );

              // add customfieldnode next to lastInputNodeIdx
              if (lastInputNodeIdx > -1) {
                node.children.splice(lastInputNodeIdx + 1, 0, customFieldNode);
              } else {
                node.children.push(customFieldNode);
              }
            }
          },
          workingFormWidget.config,
        );

        this.storeConfig({
          workingFormWidget,
          isChanged: true,
        });
      }
    },

    deleteNode(payload: { nodeId: string }, rootState) {
      const { workingFormWidget, selectedFormWidgetTreeNodeId } =
        rootState.formWidgetEditor;

      if (isPopupPromptFormWidget(workingFormWidget)) {
        workingFormWidget.config = applyChangesToNodeTree(
          selectedFormWidgetTreeNodeId,
          node => {
            if (node.type === TreeNodeType.BOX) {
              node.children = node.children.filter(
                child => child.id !== payload.nodeId,
              ) as TreeNode[];
            }
          },
          workingFormWidget.config,
        );

        this.storeConfig({
          workingFormWidget,
          isChanged: true,
        });
      }
    },

    shiftNode(
      payload: { nodeId: string; direction: 'up' | 'down' },
      rootState,
    ) {
      const { workingFormWidget } = rootState.formWidgetEditor;

      if (!isPopupPromptFormWidget(workingFormWidget)) {
        return;
      }

      const node = getTreeNodeById(workingFormWidget.config, payload.nodeId);
      if (!node) return;

      const parent = getDirectParentNode(node, workingFormWidget.config);
      if (!parent) return;

      workingFormWidget.config = applyChangesToNodeTree(
        parent.id,
        parentNode => {
          if (!Array.isArray(parentNode.children)) return;

          const nodeIndex = parentNode.children.findIndex(
            child => typeof child !== 'string' && child.id === payload.nodeId,
          );

          if (nodeIndex === -1) return;

          const swapIndex =
            payload.direction === 'up' ? nodeIndex - 1 : nodeIndex + 1;

          if (swapIndex < 0 || swapIndex >= parentNode.children.length) return;

          // Swap the nodes
          const temp = parentNode.children[nodeIndex];
          parentNode.children[nodeIndex] = parentNode.children[swapIndex];
          parentNode.children[swapIndex] = temp;
        },
        workingFormWidget.config,
      );

      this.storeConfig({
        workingFormWidget,
        isChanged: true,
      });
    },

    setFormWidgetProps(
      payload: Partial<Omit<FormWidget, 'config' | 'id'>>,
      rootState,
    ) {
      const { workingFormWidget } = rootState.formWidgetEditor;

      this.storeConfig({
        workingFormWidget: {
          ...workingFormWidget,
          ...payload,
        },
      });
    },

    resetState() {
      this.storeConfig(initialState());
    },

    setIsSelectedChannelMapped(
      payload: Partial<{ email: boolean; sms: boolean }>,
    ) {
      this.storeConfig({
        isSelectedChannelMapped: {
          ...payload,
        },
      });
    },

    setIsSmallScreen(payload: boolean) {
      this.storeConfig({
        isSmallScreen: payload,
      });
    },

    setCodeEditorContent(payload: { selectedChannel: string }, rootState) {
      const { workingFormWidget } = rootState.formWidgetEditor;
      const { formWidgetStep } = rootState.aiEditor;
      if (
        isPopupPromptFormWidget(workingFormWidget) &&
        workingFormWidget.config.type === TreeNodeType.HTML &&
        !workingFormWidget.config?.children?.[formWidgetStep]?.length
      ) {
        dispatch.formWidgetEditor.setNodeContent({
          nodeId: ROOT_NODE_ID,
          updateWith(node) {
            if (node.type === TreeNodeType.HTML) {
              const code =
                formWidgetStep === FormWidgetStep.Step2
                  ? thankYouScript
                  : script(payload.selectedChannel);
              if (!node.children) {
                node.children = new Array(2).fill('');
                node.children[formWidgetStep] = code;
                return;
              }
              node.children[formWidgetStep] = code;
            }
          },
        });
      }
    },

    createLogoNode(_, rootState) {
      const { workingFormWidget } = rootState.formWidgetEditor;
      const { user } = rootState.user;

      if (isPopupPromptFormWidget(workingFormWidget)) {
        const textContentNode = getTreeNodeById(
          workingFormWidget.config,
          ReservedContainerNodeId.TEXT_CONTENT,
        );

        if (textContentNode) {
          const exitstingLogoNode = getTreeNodeById(
            textContentNode,
            ReservedElementNodeId.LOGO,
          );

          const logoNode: TreeNodeByType<TreeNodeType.IMAGE> = {
            id: ReservedElementNodeId.LOGO,
            type: TreeNodeType.IMAGE,
            attr: {
              ...(exitstingLogoNode?.attr || {}),
              url: user?.website?.company_logo || '',
            },
          };

          workingFormWidget.config = applyChangesToNodeTree(
            textContentNode.id,
            node => {
              if (node.type === TreeNodeType.BOX) {
                const existingLogoNodeIndex = node.children?.findIndex(
                  child => child.id === ReservedElementNodeId.LOGO,
                );

                if (existingLogoNodeIndex >= 0) {
                  node.children[existingLogoNodeIndex] = logoNode;
                } else {
                  node.children = [logoNode, ...(node.children || [])];
                }
              }
            },
            workingFormWidget.config,
          );

          this.storeConfig({
            workingFormWidget,
            isChanged: true,
          });
        }
      }
    },

    createDiscountNode(
      payload: { insertInto: string; formWidget: FormWidget },
      rootState,
    ) {
      const { workingFormWidget } = rootState.formWidgetEditor;
      const formWidget = payload.formWidget || workingFormWidget;

      if (isPopupPromptFormWidget(formWidget) && payload.insertInto) {
        const insertIntoNode = getTreeNodeById(
          formWidget.config,
          payload.insertInto,
        );

        if (insertIntoNode) {
          const existingDiscountNode = getTreeNodeById(
            insertIntoNode,
            ReservedElementNodeId.DISCOUNT,
          );

          const discountNode: TreeNodeByType<TreeNodeType.DISCOUNT> = {
            id: ReservedElementNodeId.DISCOUNT,
            type: TreeNodeType.DISCOUNT,
            attr: {
              ...(existingDiscountNode?.attr || {}),
              code: '',
            },
            children: undefined,
          };

          dispatch.formWidgetEditor.setNodeContent({
            nodeId: insertIntoNode.id,
            updateWith(node) {
              if (node.type === TreeNodeType.BOX) {
                const existingDiscountNodeIndex = node.children?.findIndex(
                  child => child.id === ReservedElementNodeId.DISCOUNT,
                );

                if (existingDiscountNodeIndex >= 0) {
                  node.children[existingDiscountNodeIndex] = discountNode;
                } else {
                  node.children = [...(node.children || []), discountNode];
                }
              }
            },
            formWidget,
          });
        }
      }
    },
  }),

  reducers: {
    storeConfig(
      state: FormWidgetEditorWorkingState,
      payload: FormWidgetEditorWorkingState,
    ): FormWidgetEditorWorkingState {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default formWidgetEditor;
